import '@brainstud/core-learning/dist/index.css';
import '@brainstud/ui/dist/index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import config from 'config';
import translations from 'Translations';
import { Core } from '@brainstud/core-learning';
import { DefaultTheme } from '@brainstud/ui';
import { ApiProvider } from '@brainstud/academy-api';
import App from './App';

ReactDOM.render(
  <ApiProvider config={config.api}>
    <Core translations={translations} config={config} theme={DefaultTheme}>
      <App />
    </Core>
  </ApiProvider>,
  document.getElementById('root'),
);
