import React from 'react';
import classNames from 'classnames/bind';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslator } from '@brainstud/core-learning';
import styles from './Loading.module.css';
import animationData from './animation.json';

const cx = classNames.bind(styles);

export type LoadingProps = {
  absolute?: boolean,
  center?: boolean,
  top?: string,
  style?: React.CSSProperties,
  className?: string,
};

export const Loading = ({
  absolute, center, top, style, className,
}: LoadingProps) => {
  const [t] = useTranslator();

  return (
    <div
      className={cx('base', {
        absolute,
        center,
      }, className)}
      style={{ marginTop: top, ...style }}
    >
      <Player
        src={JSON.stringify(animationData)}
        autoplay
        loop
        style={{ width: 64, height: 64, margin: '0 auto' }}
      />
      <p>{t('loading')}</p>
    </div>
  );
};
