import routes from '../../../routes';
import { TOrderStatus } from './ConfirmationView';

export default function usePrepaidCodeConfirmation(orderStatus:TOrderStatus | false): TOrderStatus {
  if (orderStatus) {
    return orderStatus;
  }
  return {
    state: 'prepaid',
    isLoading: false,
    primaryAction: { href: window.location.origin },
    secondaryAction: { to: routes.account.purchases() },
  };
}
