import React, { useEffect, useState, useCallback } from 'react';
import { CheckboxButton } from '@brainstud/ui';
import {
  UUID,
} from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { useDebounce } from '@brainstud/universal-components';
import styles from './CheckButtonList.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** Name to identify the output with, value is provided in the onChange method */
  name: string;
  /** An array of options that the user can select */
  options: Array<{ id: UUID, value: string, checked?: boolean }>
  /** Label shown above the inputs to indicate the choices to make */
  label?: string;
  /** Event handler fired when an option is selected or deselected */
  onChange?: (name: string, value: string[]) => void,
  /** Debounce the options before firing the onChange handler */
  debounce?: number,
};

/**
  * This component will show a list of Checkbox buttons
  * You will need to provide options declared in the Props type
  * Sorting is done inside this component based on checked -> alphabetical
  */
export const CheckButtonList = ({
  label, name, onChange, options, debounce = 500,
}: Props) => {
  const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>([]);

  // First load add existing checked options to state array.
  useEffect(() => {
    setSelectedOptionIds(options.filter((item) => item.checked).map((item) => item.id));
  }, [options]);

  const [isChanged, setIsChanged] = useState(false);
  const handleChange = useCallback((optionId: string) => {
    setIsChanged(true);
    setSelectedOptionIds((prevOptions) => {
      if (prevOptions.includes(optionId)) {
        return prevOptions.filter((item) => item !== optionId);
      }
      return [...prevOptions, optionId];
    });
  }, []);

  const debouncedSelectedOptionIds = useDebounce(selectedOptionIds, debounce);
  // onChange push changes to parent
  useEffect(() => {
    if (isChanged && debouncedSelectedOptionIds === selectedOptionIds) {
      onChange?.(name, debouncedSelectedOptionIds);
      setIsChanged(false);
    }
  }, [isChanged, name, debouncedSelectedOptionIds, selectedOptionIds]);

  return (
    <fieldset className={cx(styles.base)}>
      {label && (
        <legend>{label}</legend>
      )}
      {options
        .sort((x, y) => Number(y.checked) - Number(x.checked) || x.value.localeCompare(y.value))
        .map((option) => (
          <CheckboxButton
            key={option.id}
            id={option.id}
            className={cx(styles.setting)}
            label={option.value}
            checked={selectedOptionIds.includes(option.id)}
            onChange={() => handleChange(option.id)}
          />
        ))}
    </fieldset>
  );
};
