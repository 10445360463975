import React, { useMemo } from 'react';
import { useMe, useAccountOrders } from '@brainstud/academy-api';
import { DateFormatter, useTranslator } from '@brainstud/core-learning';
import { Loading, Badge } from 'Components';
import { Grid } from '@material-ui/core';
import {
  Button, Table, Th, Td, Tr,
} from '@brainstud/ui';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { CloudDownload } from '@material-ui/icons';
import classNames from 'classnames/bind';
import styles from './OrdersView.module.css';
import routes from '../../../routes';

const cx = classNames.bind(styles);

const OrdersView = () => {
  const [t] = useTranslator();
  const [{ data: orders }, { isLoading }] = useAccountOrders();
  const ordersSorted = useMemo(() => (
    orders.sort((a, b) => (a.openDate > b.openDate ? -1 : 1))
  ), [orders]);
  const [me] = useMe();

  const hasSubscriptionOptions = me?.availablePriceModels?.includes('subscription');
  const subscription = useMemo(() => me?.subscription?.(), [me]);
  const subscriptionPlan = useMemo(() => me?.subscription?.()?.plan?.(), [me]);
  const endDateIsNear = DateFormatter.isWithinDays(subscription?.endDate, 14);

  return (
    <div className={styles.orders}>
      {isLoading || !orders || !me ? <Loading /> : (
        <>
          {hasSubscriptionOptions && !subscription?.isActive ? (
            <div style={{ marginBottom: '2rem' }}>
              <h2>{t('account.orders.no_subscription.title')}</h2>
              <p>{t('account.orders.no_subscription.text')}</p>
              <Button to={routes.orders.subscription.options()}>
                <span>{t('account.orders.no_subscription.action')}</span>
                <ArrowForward />
              </Button>
            </div>
          ) : hasSubscriptionOptions && (
            <>
              <h2>{t('account.orders.active')}</h2>
              <div className={styles.subscription}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={9}>
                    <h3>{me.portal?.().name}</h3>
                    {subscriptionPlan && (
                      <h4>{subscriptionPlan.name}</h4>
                    )}
                    <p>
                      {endDateIsNear && (
                        <>
                          <em>{t('account.orders.deadline')}</em>
                          <span> / </span>
                        </>
                      )}
                      <span>
                        {t('account.orders.subscription_ending_on')}
                        <strong>{DateFormatter.toRelativeWithinDays(subscription?.endDate, 15, '[on] cccc d MMM y')}</strong>
                      </span>
                      .
                    </p>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button to={routes.orders.subscription.options()}>
                      {t('account.orders.extend')}
                      <ArrowForward style={{ color: 'fff' }} />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {orders.length === 0 ? (
            <>
              <h2>{t('account.orders.no_history.title')}</h2>
              <p>{t('account.orders.no_history.text')}</p>
            </>
          ) : (
            <>
              <h2>{t('account.orders.history')}</h2>
              <Table>
                <Table.THead>
                  <Tr>
                    <Th>{t('account.orders.product')}</Th>
                    <Th className={cx('hidden-on-small')}>
                      {t('account.orders.purchase_date')}
                    </Th>
                    <Th>{t('account.orders.amount')}</Th>
                    <Th>PDF</Th>
                  </Tr>
                </Table.THead>
                <Table.TBody>
                  {ordersSorted.map((order) => order.orderLines?.().map((orderLine) => {
                    const date = DateFormatter.toDate(orderLine?.date);
                    const time = DateFormatter.toTime(orderLine?.date);
                    const price = orderLine.totalPriceIncVat.toFixed(2).replace(/\./, ',');
                    const scheme = order.state === 'fulfilled' ? 'green' : 'red';

                    return (
                      <Tr key={orderLine.id}>
                        <Td>
                          <strong>{orderLine.productName}</strong>
                          <br />
                          <Badge scheme={scheme}>{t(`account.orders.status.${order.state}`)}</Badge>
                        </Td>
                        <Td className={cx('hidden-on-small')}>
                          <strong>{date}</strong>
                          <div className={styles.time}>{time}</div>
                        </Td>
                        <Td style={{ fontWeight: 'bold' }}>
                          {`€ ${price}`}
                        </Td>
                        <Td href={order.links.receipt.href}>
                          {order.links.receipt.href && (
                            <CloudDownload />
                          )}
                        </Td>
                      </Tr>
                    );
                  }))}
                </Table.TBody>
              </Table>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrdersView;
