import React from 'react';
import { Button, Panel } from '@brainstud/ui';
import { useTranslator } from '@brainstud/core-learning';
import confirmation from 'Assets/images/confirmation.svg';
import { Loading } from 'Components';
import styles from './ConfirmationView.module.css';
import useOrderConfirmation from './useOrderConfirmation';
import usePrepaidCodeConfirmation from './usePrepaidConfirmation';

export type TOrderState = 'created' | 'failed' | 'fulfilled' | 'prepaid';
export type TOrderStatus = {
  state: TOrderState,
  primaryAction: { to?: string, href?: string },
  secondaryAction: { to?: string, href?: string },
  isLoading: boolean,
};

const ConfirmationView = () => {
  const [t] = useTranslator();
  const {
    state,
    isLoading,
    primaryAction,
    secondaryAction,
  } = usePrepaidCodeConfirmation(useOrderConfirmation());

  return (
    <section className={styles.base}>
      <Panel className={styles.confirmation}>
        {isLoading ? <Loading /> : (
          <>
            {['fulfilled', 'prepaid'].indexOf(state) !== -1 && <img src={confirmation} alt={t('checkout.payment_successful')} />}
            <h4>{t(`checkout.confirmation.${state}.title`)}</h4>
            <p>{t(`checkout.confirmation.${state}.text`)}</p>
          </>
        )}
      </Panel>

      {!isLoading && (
        <footer>
          <div>
            {/* eslint-disable react/jsx-props-no-spreading */}
            <Button outline {...secondaryAction}>
              {t(`checkout.confirmation.${state}.secondary_action`)}
            </Button>
            <Button loading={isLoading} {...primaryAction}>
              {t(`checkout.confirmation.${state}.primary_action`)}
            </Button>
          </div>
        </footer>
      )}
    </section>
  );
};

export default ConfirmationView;
