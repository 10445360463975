import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames/bind';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Hidden } from '@material-ui/core';
import {
  Callout, Button, Panel, ConfirmationModal, Input,
} from '@brainstud/ui';
import { sanitizer, useModals, useTranslator } from '@brainstud/core-learning';
import { useMe, useOrder, usePaymentMethods } from '@brainstud/academy-api';
import styles from './PaymentMethodsView.module.css';
import routes from '../../../routes';
import { Loading } from '../../../Components';

const cx = classNames.bind(styles);

const PaymentMethodsView = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [t] = useTranslator();
  const [paymentMethods, { isLoading }] = usePaymentMethods();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [me] = useMe();
  const portal = useMemo(() => me?.portal?.(), [me]);

  const privacyDocumentUrl = useMemo(() => (
    portal?.parameters.find((p) => p.type === 'privacy_document_url')?.data
  ), [portal]);

  const tacDocumentUrl = useMemo(() => (
    portal?.parameters.find((p) => p.type === 'tac_document_url')?.data
  ), [portal]);

  const { orderId } = useParams<{ orderId: string }>();
  const [{ data: order, update: updateOrder }] = useOrder({ order: orderId });
  const orderLines = useMemo(() => order?.orderLines?.() || [], [order]);

  const proceed = useCallback(() => {
    setLoading(true);
    updateOrder.mutate({
      pay_via: 'transaction',
      payment_method: selectedPaymentMethod.mollieId,
      state: 'created',
    }, {
      onSettled: () => setLoading(false),
      onSuccess: (document) => {
        const paymentUrl = document.data.transaction?.().paymentUrl;
        if (paymentUrl) {
          window.location.href = paymentUrl;
        } else {
          history.push(routes.orders.payment.confirmation(document.data.id));
        }
      },
      onError: (document) => {
        throw Error(document.errors.map((error) => error.title).join('<br />'));
      },
    });
  }, [selectedPaymentMethod, updateOrder, history]);

  const productNames = useMemo(() => orderLines.map((item) => item.productName), [orderLines]);

  const { openModal } = useModals();
  const handleConfirmCancel = useCallback(() => {
    openModal(ConfirmationModal, {
      closeable: true,
      question: t('checkout.cancel.are_you_sure'),
      yes: t('checkout.cancel.yes'),
      no: t('checkout.cancel.no'),
      handleConfirm: () => {
        window.location.href = window.location.origin;
      },
    });
  }, [t, openModal]);

  const colorMap = {
    ideal: '204,0,102',
    banktransfer: '86,189,145',
    paypal: '0,156,222',
    creditcard: '27,33,107',
    applepay: '0,0,0',
    other: '247,147,26',
  } as { [t: string]: string };
  const warningsMap = ({
    banktransfer: t('checkout.payment_methods.warnings.delay'),
  } as { [t: string]: string });

  // Prepaid code payment method
  const prepaidCode = useRef<HTMLInputElement | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [prepaidRedeemed, setPrepaidRedeemed] = useState(false);

  const redeemPrepaid = useCallback(() => {
    const currentPrepaidCode = prepaidCode.current?.value;
    setLoading(true);
    if (currentPrepaidCode) {
      updateOrder.mutate({
        pay_via: currentPrepaidCode,
        payment_method: 'prepaid',
      }, {
        onSettled: (document) => {
          setLoading(false);
          if (document?.statusCode === 200) {
            history.push(`/orders/${orderId}/confirmation`);
          }
        },
        onSuccess: () => {
          setIsValid(true);
          setShowValidation(true);
          setPrepaidRedeemed(true);
        },
        onError: () => {
          setShowValidation(true);
        },
      });
    }
  }, []);

  return (
    <>
      <Panel className={styles.panel}>
        <main>
          <p><strong>{t('checkout.payment_methods.title')}</strong></p>
          {isLoading ? <Loading /> : (
            <Grid container className={cx('paymentMethods', { active: Boolean(selectedPaymentMethod) })} spacing={1}>
              {paymentMethods.map((paymentMethod) => (
                <Grid item xs={12} md={6} key={paymentMethod.id}>
                  <button
                    type="button"
                    style={{ background: `rgb(${colorMap[paymentMethod.id] || colorMap.other})` }}
                    className={cx('paymentMethod', { active: paymentMethod.id === selectedPaymentMethod?.id })}
                    onClick={() => setSelectedPaymentMethod(paymentMethod)}
                  >
                    <img src={paymentMethod.image} alt="" />
                    <strong>
                      {t(`checkout.payment_methods.methods.${paymentMethod.id}`, {}, paymentMethod.description)}
                    </strong>
                  </button>
                </Grid>
              ))}
            </Grid>
          )}
          {warningsMap[selectedPaymentMethod?.id] && (
            <Callout warning margin="2rem 0" style={{ fontSize: '1.2rem' }}>
              <div dangerouslySetInnerHTML={{ __html: sanitizer(warningsMap[selectedPaymentMethod.id]) }} />
            </Callout>
          )}
          <div className={cx(styles['prepaid-wrapper'])}>
            <Input
              id="checkout_prepaid"
              name="prepaid-code"
              label={t('checkout.payment_methods.prepaid.label', { productNames })}
              ref={prepaidCode}
              valid={showValidation ? isValid : undefined}
            />
            <div className={cx(styles['btn-wrapper'])}>
              <Button
                loading={loading}
                onClick={redeemPrepaid}
              >
                {t('checkout.payment_methods.prepaid.redeem')}
              </Button>
            </div>
          </div>
          {(showValidation && isValid) && (
            <Callout success icon={false}>
              <span>{t('checkout.payment_methods.prepaid.success')}</span>
            </Callout>
          )}
          {(showValidation && !isValid) && (
          <Callout error icon={false}>
            <span>{t('checkout.payment_methods.prepaid.error')}</span>
          </Callout>
          )}

        </main>
        <aside>
          <section>
            <strong className={styles.yourOrder}>{t('checkout.your_order')}</strong>
          </section>
          <section className={styles.gray}>
            {orderLines.map((line) => {
              const priceOfLine = (parseFloat(line.price) / 100).toLocaleString('nl-nl', {
                style: 'currency',
                currency: line.currency,
              });
              return (
                <div
                  key={line.productName}
                  className={styles.orderLine}
                >
                  <div className={styles.productTitle}>{line.productName}</div>
                  <p className={styles.productPrice}>
                    {/* @ts-ignore FIXME when vatRate has been added to OrderLine */}
                    {line.vatRate === 0 ? t('checkout.vatFree') : t('checkout.exVat', { vat: line.vatRate })}
                    :
                    <span style={{ textDecoration: prepaidRedeemed ? 'line-through' : 'none' }}>{priceOfLine}</span>
                  </p>
                </div>
              );
            })}
          </section>
          <Hidden smDown>
            <section>
              <p><strong>{t('checkout.selected_payment_method')}</strong></p>
              <p>
                {' '}
                {t(`checkout.payment_methods.methods.${selectedPaymentMethod?.id}`, {}, selectedPaymentMethod?.description || '-')}
              </p>
            </section>
          </Hidden>
          <section>
            <h3 className={styles.total}>
              <span>
                {t('checkout.total')}
                {' '}
              </span>
              {prepaidRedeemed ? (
                <span> &euro; 0 </span>
              ) : (
                <span>
                  <span className={styles.totalPriceVat}>{t('checkout.incVat')}</span>
                  {' '}
                  &euro;
                  {order?.totalOrderWorth?.toFixed(2).replace(/\./, ',')}
                </span>
              )}
            </h3>
          </section>
        </aside>
      </Panel>
      <div className={styles.footer}>
        <div className={styles.footerButtons}>
          <Button type="button" outline onClick={handleConfirmCancel}>
            {t('checkout.cancel_order')}
          </Button>
          <Button
            disabled={!selectedPaymentMethod}
            loading={loading}
            onClick={proceed}
          >
            {t('checkout.proceed_to_payment')}
          </Button>
        </div>
        <div className={styles.terms}>
          <p>
            <strong>{t('checkout.agree_to_terms')}</strong>
          </p>
          <ul>
            {tacDocumentUrl && <li><a href={tacDocumentUrl} target="_blank" rel="noopener noreferrer">{t('checkout.terms_and_conditions')}</a></li>}
            {privacyDocumentUrl && <li><a href={privacyDocumentUrl} target="_blank" rel="noopener noreferrer">{t('checkout.privacy_declaration')}</a></li>}
            {orderLines.map((line) => line.terms?.map((term) => (
              <li key={term.name}><a href={term.url} target="_blank" rel="noopener noreferrer">{term.name}</a></li>
            )))}
          </ul>
          <p>
            <small>
              {t('checkout.mollie')}
            </small>
          </p>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodsView;
