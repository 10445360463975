import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import {
  Callout, Button, Panel, Input,
} from '@brainstud/ui';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { DateFormatter, useTranslator } from '@brainstud/core-learning';
import {
  useMe, useProducts, usePrepaidCodeActivation, useOrders,
} from '@brainstud/academy-api';
import { SubscriptionCard, PlanCard, Loading } from 'Components';
import classNames from 'classnames/bind';
import styles from './SubscriptionsView.module.css';
import routes from '../../../routes';

const cx = classNames.bind(styles);

const SubscriptionsView = () => {
  const prepaidInputField = useRef<HTMLInputElement>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [prepaidCode, setPrepaidCode] = useState<string>('');
  const [t] = useTranslator();
  const [{ data: products }, { isLoading }] = useProducts();
  const redeemPrepaidCode = usePrepaidCodeActivation();

  const [me] = useMe();
  const portal = me?.portal?.();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Array<{ code: string }>>([]);

  const allPlans = useMemo(() => products.filter((product) => product.productType === 'plan_option'), [products]);
  const durationOptions = useMemo(() => [
    ...new Set(allPlans.map((plan) => plan.productModel?.().duration || '')),
  ], [allPlans]);
  const [duration, setDuration] = useState(durationOptions[0]);
  useEffect(() => setDuration(durationOptions[0]), [durationOptions]);

  const subscriptions = useMemo(() => (
    products.filter((product) => product.productType === 'subscription_option')
  ), [products]);
  const plans = useMemo(() => (
    products.filter((product) => product.productType === 'plan_option'
      && product.productModel?.().duration === duration)
      .sort((a, b) => {
        if (a.priceAfterVat && b.priceAfterVat && a.priceAfterVat > b.priceAfterVat) return 1;
        if (a.priceAfterVat && b.priceAfterVat && a.priceAfterVat < b.priceAfterVat) return -1;
        return 0;
      })
  ), [duration, products]);
  const isPlanBased = plans.length > 0;
  const subscriptionEndDate = me?.subscription?.().endDate;
  const hasActiveSubscription = me?.subscription?.().isActive && !DateFormatter.isWithinDays(subscriptionEndDate, 10);

  const { push: goToPage } = useHistory();
  const [{ create: createOrder }] = useOrders({}, { enabled: false });
  const history = useHistory();

  const hasPrepaidCode = useMemo(() => prepaidCode?.length !== 0, [prepaidCode]);

  const checkPrepaidCode = useCallback((code) => {
    setLoading(true);
    redeemPrepaidCode.mutate({
      prepaid_code: code,
    }, {
      onSuccess: () => {
        goToPage(routes.orders.prepaid.confirmation());
      },
      onError: (errorDocument) => {
        setLoading(false);
        if (errorDocument.statusCode === 500) {
          setErrors([{ code: 'ERROR_500' }]);
        } else {
          setErrors((errorDocument.errors) || [{ code: t('error') }]);
        }
        prepaidInputField.current?.focus();
      },
    });
  }, [redeemPrepaidCode, prepaidInputField, goToPage, t]);

  const handleSubmit = useCallback(() => {
    if (hasPrepaidCode) {
      checkPrepaidCode(prepaidCode);
    } else if (selectedOption) {
      setLoading(true);
      createOrder.mutate({
        order_lines: [{ catalog_product: selectedOption }],
        state: 'pending',
      }, {
        onSuccess: (document) => {
          history.push(routes.orders.payment.buy_order(document.data.id));
        },
        onError: (document) => {
          setLoading(false);
          throw Error(document.errors.map((error) => error.title).join('<br />'));
        },
      });
    }
  }, [hasPrepaidCode, selectedOption, checkPrepaidCode, prepaidCode, createOrder, history]);

  return (
    <div className={styles.base}>
      <Panel>
        <p className={cx(styles.subscription)}>
          {t('checkout.choose_subscription')}
          {': '}
          <strong>{portal?.name}</strong>
        </p>
        {isPlanBased && hasActiveSubscription ? (
          <div>
            <Callout warning>
              <h3>{t('checkout.plan.not_available.title')}</h3>
              <p>{t('checkout.plan.not_available.description')}</p>
            </Callout>
          </div>
        ) : (
          <>
            {isPlanBased && (
              <div className={cx(styles['duration-switch'])}>
                <strong>
                  {`${t('checkout.duration.choose')}: `}
                </strong>

                <div className={cx(styles.options)}>
                  {durationOptions.map((durationOption) => (
                    <Button
                      key={durationOption}
                      onClick={() => {
                        setSelectedOption(null);
                        setDuration(durationOption);
                      }}
                      className={cx(styles.option)}
                      outline={duration !== durationOption}
                    >
                      <strong>{t(`checkout.duration.${durationOption}.title`)}</strong>
                      <span>
                        (
                        {t(`checkout.duration.${durationOption}.name`)}
                        )
                      </span>
                    </Button>
                  ))}
                </div>
              </div>
            )}
            <div className={cx(styles.plans)}>
              {isLoading ? <Loading /> : (
                <Grid container spacing={2}>
                  {subscriptions.map((subscription) => (
                    <SubscriptionCard
                      key={subscription.id}
                      subscription={subscription}
                      onSelect={setSelectedOption}
                      selected={subscription.id === selectedOption && prepaidCode.length === 0}
                      disabled={prepaidCode.length > 0}
                    />
                  ))}
                  {plans.map((plan) => (
                    <PlanCard
                      key={plan.id}
                      plan={plan}
                      onSelect={setSelectedOption}
                      selected={plan.id === selectedOption && prepaidCode.length === 0}
                      disabled={prepaidCode.length > 0}
                    />
                  ))}
                </Grid>
              )}
            </div>

            <div className={styles.prepaid}>
              <Input
                name="prepaid_code"
                label={t('checkout.fill_in_prepaid_code')}
                ref={prepaidInputField}
                disabled={loading}
                placeholder={t('checkout.prepaid_code')}
                onChange={(e) => setPrepaidCode(e.target.value)}
              />
              <Button
                quiet
                onClick={handleSubmit}
              >
                {t('checkout.redeem')}
              </Button>
            </div>
            {errors.length > 0 && errors.map((error) => (
              <Callout key={error.code} error margin="1rem 0" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <p>{error.code ? t(`errors.${error.code}`) : t('error')}</p>
              </Callout>
            ))}
          </>
        )}

        <footer>
          <Button quiet onClick={() => window.history.go(-1)}>
            <ChevronLeft fontSize="large" />
            <span>{t('go_back')}</span>
          </Button>
          <Button
            disabled={!selectedOption && prepaidCode.length === 0}
            loading={loading}
            onClick={handleSubmit}
          >
            <span>{t('checkout.select_payment_method')}</span>
          </Button>
        </footer>
      </Panel>

      <h3>{t('checkout.usp.title')}</h3>
      <ul className={styles.usps}>
        <li>{t('checkout.usp.first')}</li>
        <li>{t('checkout.usp.second')}</li>
        <li>{t('checkout.usp.third')}</li>
      </ul>
    </div>
  );
};

export default SubscriptionsView;
