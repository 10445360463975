import React, { ReactChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './Badge.module.css';

const cx = classNames.bind(styles);

type TProps = {
  scheme: 'green' | 'yellow' | 'blue' | 'orange' | 'red' | 'white' | 'orange',
  outline?: boolean,
  children?: string | ReactChildren,
  className?: string,
  style?: React.CSSProperties,
};

const Badge = ({
  children, scheme, outline, style, className,
}: TProps = { scheme: 'white' }) => {
  const property = outline ? 'borderColor' : 'backgroundColor';
  const color = {
    green: 'rgb(69,208,154)',
    yellow: 'rgb(255,204,65)',
    black: 'rgb(32,35,43)',
    blue: 'rgb(0,148,195)',
    white: 'rgb(69,208,154)',
    orange: 'rgb(195,117,0)',
    red: 'rgb(216,90,90)',
  }[scheme];
  const textColor = {
    green: 'rgb(var(--dark-text-color))',
    yellow: 'rgb(var(--dark-text-color))',
    black: 'rgb(var(--light-text-color))',
    blue: 'rgb(var(--light-text-color))',
    white: 'rgb(var(--dark-text-color))',
    orange: 'rgb(var(--dark-text-color))',
    red: 'rgb(var(--light-text-color))',
  }[scheme];

  const colorScheme = {
    [property]: color,
    color: outline ? color : textColor,
  };

  return (
    <div className={cx(styles.base, { outline }, className)} style={{ ...colorScheme, ...style }}>
      {children}
    </div>
  );
};

export default Badge;
