import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { useAuthenticationMethods } from '@brainstud/academy-api';
import { sanitizer, useModals, useTranslator } from '@brainstud/core-learning';
import { Panel, Button } from '@brainstud/ui';
import { Loading } from 'Components';
import { SubHeader } from 'Layouts';
import HelpOutline from '@material-ui/icons/HelpOutline';
import styles from './MethodView.module.css';
import MethodTooltip from './MethodTooltip';

const cx = classNames.bind(styles);

const MethodView = () => {
  const [t] = useTranslator();
  const [{ data: methods }, { isLoading }] = useAuthenticationMethods();

  const { openModal } = useModals();
  const handleTooltip = useCallback((event, method) => {
    event.preventDefault();
    openModal(MethodTooltip, { method });
  }, []);

  return (isLoading) ? <Loading /> : (
    <>
      <Panel className={cx(styles.base)}>
        <SubHeader>
          <h1>{t('method.title')}</h1>
          <p>{t('method.subtitle')}</p>
        </SubHeader>
        <div className={cx(styles.methods)}>
          {methods.map((method) => (
            <React.Fragment key={method.id}>
              <a href={method.url || '#/login'} className={cx(styles.method)}>
                {method.icon && <img src={method.icon} alt="" className={cx(styles.icon)} />}
                <span className={cx(styles.methodText, { extraMargin: !!method.icon })}>
                  <span className={cx(styles.label)}>
                    {method.label}
                  </span>
                  {method.description && (
                    <span
                      className={cx(styles.description)}
                      dangerouslySetInnerHTML={{ __html: sanitizer(method.description) }}
                    />
                  )}
                </span>
                {method.tooltip && (
                <Button
                  title={t('methods.tooltip')}
                  onClick={(e: React.MouseEvent) => handleTooltip(e, method)}
                  quiet
                  round
                  className={cx(styles.tooltip)}
                >
                  <HelpOutline />
                </Button>
                )}
              </a>
            </React.Fragment>
          ))}
        </div>
      </Panel>
    </>
  );
};

export default MethodView;
