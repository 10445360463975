import React, { useMemo } from 'react';
import className from 'classnames/bind';
import styles from './ProgressStepper.module.css';

export type ProgressStepperProps = {
  step: number,
  length?: number,
  labels?: Array<string>,
  onClick?: () => void,
};

const cx = className.bind(styles);

const ProgressStepper = ({ step, length, labels }: ProgressStepperProps) => {
  const steps = useMemo(() => labels || [...Array(length)], [labels, length]);

  return (
    <ol className={cx('stepper', { showLabel: Boolean(labels) })}>
      {steps.map((label, i) => (
        <li
          key={label}
          className={cx({ active: i + 1 <= step })}
        >
          <span>{label}</span>
        </li>
      ))}
    </ol>
  );
};

export default ProgressStepper;
