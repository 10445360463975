import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import classNames from 'classnames/bind';
import { Link, useHistory } from 'react-router-dom';
import { useMe } from '@brainstud/academy-api';
import { useOnClickOutside } from '@brainstud/universal-components';
import { useTranslator } from '@brainstud/core-learning';
import {
  AccountCircle, CreditCard, ExitToApp, Help,
} from '@material-ui/icons';
import routes from 'routes';
import styles from './AccountSubmenu.module.css';

const cx = classNames.bind(styles);

type TProps = {
  open: boolean,
  opener: React.MutableRefObject<any>,
  onClose: () => void,
};

const AccountSubmenu = ({ open, opener, onClose }:TProps) => {
  const [me] = useMe();
  const baseElement = useRef(null);
  const [t] = useTranslator();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(handleClose);
    return () => {
      unlisten();
    };
  }, [handleClose, history]);

  useOnClickOutside([baseElement, opener], handleClose);

  const account = useMemo(() => me?.account?.(), [me]);

  return (
    <nav className={cx('base', { hidden: !open })} ref={baseElement}>
      {!!account && (
        <>
          <div className={cx('group')}>
            <Link to={routes.account.details()}>
              <AccountCircle fontSize="large" />
              <span>{t('menu.account_details')}</span>
            </Link>
            <Link to={routes.account.purchases()}>
              <CreditCard fontSize="large" />
              <span>{t('menu.subscription')}</span>
            </Link>
            {/* @ts-ignore FIXME: Remove ts-ignore when information is added in academy api */}
            {me?.portal?.().information.knowledgeBaseUrl && (
              // @ts-ignore FIXME: Remove ts-ignore when information is added in academy api
              <a href={me?.portal?.().information.knowledgeBaseUrl}>
                <Help fontSize="large" />
                <span>{t('menu.help')}</span>
              </a>
            )}
            <a href={routes.account.logout()}>
              <ExitToApp fontSize="large" />
              <span>{t('menu.sign_out')}</span>
            </a>
          </div>
        </>
      )}
    </nav>
  );
};

export default AccountSubmenu;
