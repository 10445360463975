import React, {
  ChangeEventHandler, useCallback, useEffect, useState,
} from 'react';
import {
  useNotificationPreference,
  NotificationType,
} from '@brainstud/academy-api';
import { useTranslator } from '@brainstud/core-learning';
import { Toggle } from '@brainstud/ui';
import classNames from 'classnames/bind';
import styles from './NotificationItem.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** Single notificationType with possible notificationPreference relationship */
  resource: NotificationType
};

/**
 *
 * This component will show a toggle for email and panel (notificationPreference) per received
 * notificationType. When changing a toggle this component will handle the creation and update
 * of the preference.
 */
export const NotificationItem = ({ resource }: Props) => {
  const [t] = useTranslator();
  const [{ createOrUpdate }] = useNotificationPreference({
    notificationPreference: resource?.preference?.().id,
  }, { enabled: false });

  const [platformPreference, setPlatformPreference] = useState<boolean>(true);
  const [emailPreference, setEmailPreference] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  // set the correct state on basis of prop value
  useEffect(() => {
    if (resource?.preference?.().id) {
      setPlatformPreference(resource?.preference?.().notify.platform);
      setEmailPreference(resource?.preference?.().notify.email);
    }
  }, [resource]);

  const hasPreference = resource.preference?.().id;

  const handleToggleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setLoading(true);
    switch (e.target.name) {
      case 'email':
        setEmailPreference((prevState) => !prevState);
        break;
      case 'platform':
        setPlatformPreference((prevState) => !prevState);
        break;
      default:
        break;
    }
    createOrUpdate.mutate({
      notify: {
        email: e.target.name === 'email' ? !emailPreference : emailPreference,
        platform: e.target.name === 'platform' ? !platformPreference : platformPreference,
      },
      ...(!hasPreference ? {
        relationships: {
          notification_type: resource.id,
        },
      } : {}
      ),
    },
    {
      onSettled: () => {
        setLoading(false);
      },
    });
  }, [emailPreference, platformPreference]);

  return (
    <>
      <div>
        {t(`notifications.notification_titles.${resource.category}.${resource.key}`)}
      </div>
      <div className={cx(styles['toggle-wrapper'])}>
        <Toggle
          name="platform"
          disabled={loading}
          checked={platformPreference}
          onChange={handleToggleChange}
        >
          <span className={cx(styles.label)}>{t('notifications.platform')}</span>
        </Toggle>
        <Toggle
          name="email"
          disabled={loading}
          checked={emailPreference}
          onChange={handleToggleChange}
        >
          <span className={cx(styles.label)}>{t('notifications.email')}</span>
        </Toggle>
      </div>
    </>
  );
};
