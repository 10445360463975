import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Hidden } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Button } from '@brainstud/ui';
import { useMe } from '@brainstud/academy-api';
import { useTheme, useTranslator } from '@brainstud/core-learning';
import { useWindowSize } from '@brainstud/universal-components';
import { useScrollPosition } from 'Hooks';
import { ReactComponent as MenuSVG } from 'Assets/menu-icon.svg';
import { ReactComponent as LogoSVG } from 'Assets/logo.svg';
import styles from './Header.module.css';
import AccountSubmenu from './Components/AccountSubmenu';

const cx = classNames.bind(styles);

type TProps = {
  className?: string,
  style?: React.CSSProperties,
};

const Header = ({ className, style }: TProps) => {
  const [t] = useTranslator();
  const [me, { isLoading }] = useMe();
  const [menu, setMenu] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const accountButton = useRef(null);
  const avatarButton = useRef();

  useScrollPosition(({ y }: { y: Number }) => {
    setHideMenu(y > 50);
  });

  const [{ assets }] = useTheme();
  const logo = assets?.logo;
  const { width: windowWidth } = useWindowSize();
  const isSmallWindow = windowWidth && windowWidth <= 960;

  const isLoggedIn = useMemo(() => !!me?.account, [me]);

  return isLoading ? null : (
    <header className={cx('base', { 'mobile-scroll': isSmallWindow && hideMenu }, className)} style={style}>
      <div className={cx('background')} />
      <div className={cx(styles.logo, 'ie-logo')}>
        <Button className={cx('logo-button', { loading: isLoading })} href="/">
          {!isLoading && (logo ? <img src={logo} alt={`${me?.entity?.()?.name} logo`} />
            : <LogoSVG />)}
        </Button>
      </div>
      {!isLoggedIn
        && (
        <>
          {/* Desktop */}
          <Hidden smDown>
            <div className={cx('header-menu-login')}>
              <Button quiet href="/account/start" className={styles.loginButton}>
                <span>{t('menu.login')}</span>
              </Button>
              <Button className={cx('header-menu-button')} href="/account/start">
                <span>{t('menu.tenDaysFree')}</span>
              </Button>
            </div>
          </Hidden>
          {/* Mobile */}
          <Hidden mdUp>
            <div className={cx('header-menu')}>
              <Button link to="/login">
                <span>{t('menu.login')}</span>
              </Button>
            </div>
          </Hidden>
        </>
        )}
      <div className={cx('submenu')}>
        <Button
          quiet
          ref={accountButton}
          className={cx('submenu-button')}
          onClick={() => setMenu((open) => !open)}
        >
          <span>{me?.account?.()?.firstName || ''}</span>
          {menu ? <Close fontSize="large" /> : <MenuSVG />}
        </Button>
      </div>
      <AccountSubmenu
        open={menu}
        opener={isSmallWindow ? avatarButton : accountButton}
        onClose={() => setMenu(false)}
      />
    </header>
  );
};

export default Header;
