import React, { useState } from 'react';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Button, Input, Panel } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import axios from 'axios';
import { SubHeader } from 'Layouts';
import classNames from 'classnames/bind';
import { sanitizer, useConfig, useTranslator } from '@brainstud/core-learning';
import { useMe } from '@brainstud/academy-api';
import useApp from 'Layouts/AppProvider/useApp';
import { Loading } from 'Components/Loading/Loading';
import styles from './LoginView.module.css';

const cx = classNames.bind(styles);
const LoginView = () => {
  const endpoint = useConfig('api.baseUrl');
  const [me] = useMe();
  const [t] = useTranslator();

  const getFeedbackMessage = (statusCode: number) => {
    if (statusCode === 401) return t('login.messages.failed');
    if (statusCode === 422) return t('login.messages.failed');
    if (statusCode === 500) return t('login.messages.error');
    return '';
  };

  const { setFlash } = useApp();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (values: { email_address: string, password: string }) => {
    setLoading(true);
    axios.post(`${endpoint}/v0/login`, {
      email_address: values.email_address,
      password: values.password,
    })
      .then((response) => {
        window.location.assign(response.data.web_access_url);
      })
      .catch((error) => {
        setLoading(false);
        setFlash(getFeedbackMessage(error?.response?.status || 401), 'error', null, 5000);
      });
  };

  return (
    <div className={cx(styles.base)}>
      {loading && <Loading absolute center />}
      <div className={cx({ 'is-loading': loading })}>
        <Panel className={cx(styles.panel)}>
          <SubHeader back>
            <h1 dangerouslySetInnerHTML={{ __html: sanitizer(t('login.title', { name: me?.portal?.().name })) }} />
            <p>{t('login.subtitle')}</p>
          </SubHeader>
          <Form onSubmit={handleFormSubmit} autoReset={false}>
            <Input type="email" label={t('login.email')} name="email_address" rules="required" />
            <Input type="password" label={t('login.password')} name="password" rules="required" />
            <p>
              <a href="#/register">{t('login.register')}</a>
              {' '}
              {t('or')}
              {' '}
              <a href="#/forgot-password">{t('login.forgot_password')}</a>
            </p>
            <div style={{ marginTop: '2rem' }}>
              <Button style={{ marginRight: '1rem' }}>
                <span>{t('login.action')}</span>
                <ChevronRight fontSize="small" />
              </Button>
              <Button type="reset" outline to="/">{t('go_back')}</Button>
            </div>
          </Form>
        </Panel>
        <Panel className={cx(styles.panel, styles.small, 'bottom-panel')}>
          <p>
            {`${t('login.no_account')} `}
            <a href="#/register">{t('login.no_account_register')}</a>
          </p>
        </Panel>
      </div>
    </div>
  );
};

export default LoginView;
