import { useState, useEffect, useLayoutEffect } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

const isBrowser = typeof window !== 'undefined';

function getScrollPosition(element?: { current: HTMLElement }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return { x: -position.left, y: -position.top };
}

export default function useScrollPosition(
  callback?: (p: ScrollPosition) => void,
  condition?: (p: ScrollPosition) => boolean,
): ScrollPosition {
  const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition());

  useEffect(() => { // eslint-disable-line consistent-return
    let requestRunning: number | null = null;
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    if (isBrowser) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useLayoutEffect(() => {
    if (callback && (!condition || (condition && condition(position)))) {
      callback(position);
    }
  }, [callback, position, condition]);

  return position;
}
