import React from 'react';
import classNames from 'classnames/bind';
import { useMe } from '@brainstud/academy-api';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { ReactComponent as LogoSVG } from 'Assets/images/logo.svg';
import { Button } from '@brainstud/ui';
import { useHistory } from 'react-router-dom';
import styles from './SubHeader.module.css';

const cx = classNames.bind(styles);

export type HeaderProps = {
  back?: boolean,
  children?: React.ReactNode,
};

const SubHeader = ({ back, children }: HeaderProps) => {
  const [me] = useMe();
  const portal = me?.portal;
  const logo = me?.theme?.assets?.logo;
  const history = useHistory();

  return (
    <header className={cx(styles.base)}>
      {back && (
        <Button onClick={() => history.go(-1)} quiet small className={cx('back-button')}>
          <KeyboardArrowLeft fontSize="large" />
        </Button>
      )}

      <div className={cx('logo-container')}>
        {logo ? <img className="logo" src={logo} alt={`${portal?.name} logo`} /> : <LogoSVG />}
      </div>

      <div className={styles.heading}>
        {children}
      </div>
    </header>
  );
};

export default SubHeader;
