import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Grid } from '@material-ui/core';
import {
  Button, ConfirmationModal, Dropdown, Input, Panel, Callout,
} from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { useOrder, useOrderRegistration } from '@brainstud/academy-api';
import routes from 'routes';
import { useModals, useTranslator } from '@brainstud/core-learning';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './PreRegistrationView.module.css';
import useApp from '../../../Layouts/AppProvider/useApp';
import { Loading } from '../../../Components';

type FormData = {
  first_name: string,
  last_name: string,
  bsn: string,
  gender: 'man' | 'vrouw',
  email: string,
  birth_date_day: string,
  birth_date_month: string,
  birth_date_year: string,
  address_street: string,
  address_street_number: string,
  address_postal_code: string,
  address_city: string,
  address_province: string,
};

const DAYS = [...Array(31)
  .keys()].map((i) => (i + 1).toString());
const YEARS = [...Array(70)
  .keys()].map((i) => (2010 - i).toString());

const PreRegistrationView = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [t] = useTranslator();
  const { orderId } = useParams<{ orderId: string }>();
  const [{ data: order }, { isLoading }] = useOrder({ order: orderId });
  const create = useOrderRegistration({ order: orderId });
  const months = t('months') as unknown as string[];
  const provinces = t('provinces') as unknown as string[];
  const entries = order?.preRegistrationForm;
  const { setFlash } = useApp();

  const handleSubmit = useCallback(async (data:FormData) => {
    setLoading(true);
    return create.mutateAsync({
      ...data,
      ...(entries?.birthDate && (data.birth_date_day && data.birth_date_month && data.birth_date_year) ? {
        birth_date: DateTime.fromObject({
          year: Number.parseInt(data.birth_date_year, 10),
          month: Number.parseInt(data.birth_date_month, 10),
          day: Number.parseInt(data.birth_date_day, 10),
        })
          .toISODate(),
      } : {}),
      address_country: 'Nederland',
    }, {
      onSettled: () => setLoading(false),
      onError: (document) => {
        if (document.statusCode !== 422) {
          setFlash(document.errors.map((error) => error.detail)
            .join('<br />'), 'error', null, 5000);
        }
      },
    });
  }, [create, entries?.birthDate, setFlash]);

  const handleSuccess = useCallback(() => {
    history.push(routes.orders.payment.buy_order(orderId));
  }, [history, orderId]);

  const { openModal } = useModals();
  const handleConfirmCancel = useCallback(() => {
    openModal(ConfirmationModal, {
      closeable: true,
      question: t('checkout.cancel.are_you_sure'),
      yes: t('checkout.cancel.yes'),
      no: t('checkout.cancel.no'),
      handleConfirm: () => {
        window.location.href = window.location.origin;
      },
    });
  }, [t, openModal]);

  const requiresAddressInfo = entries?.addressCity
    || entries?.addressCountry
    || entries?.addressProvince
    || entries?.addressStreet
    || entries?.addressStreetNumber
    || entries?.addressPostalCode;
  return isLoading ? <Loading /> : (
    <Form<FormData> onSubmit={handleSubmit} onSuccess={handleSuccess} language="nl">
      <Panel className={styles.panel}>
        <Grid item xs={12}>
          <h3>{t('pre_registration.personal_details')}</h3>
        </Grid>
        {!entries && (
          <Callout info>
            {t('pre_registration.no_data_required')}
          </Callout>
        )}
        <Grid container spacing={1}>
          {entries?.firstName && (
            <Grid item xs={12} md={6}>
              <Input
                id="first_name"
                name="first_name"
                label={t('pre_registration.first_name')}
                rules={entries.firstName}
              />
            </Grid>
          )}
          {entries?.lastName && (
            <Grid item xs={12} md={6}>
              <Input
                id="last_name"
                name="last_name"
                label={t('pre_registration.last_name')}
                rules={entries.lastName}
              />
            </Grid>
          )}
          {entries?.bsn && (
            <Grid item xs={12} md={6}>
              <Input
                id="bsn"
                name="bsn"
                type="number"
                label={t('pre_registration.bsn')}
                rules={entries.bsn}
              />
            </Grid>
          )}
          {entries?.gender && (
            <Grid item xs={12} md={6}>
              <Dropdown
                id="gender"
                name="gender"
                label={t('pre_registration.gender')}
                placeholder={t('pre_registration.select')}
                rules={entries?.gender}
              >
                <Dropdown.Option value="man">{t('pre_registration.man')}</Dropdown.Option>
                <Dropdown.Option value="vrouw">{t('pre_registration.woman')}</Dropdown.Option>
              </Dropdown>
            </Grid>
          )}

          {entries?.email && (
            <Grid item xs={12} md={6}>
              <Input
                id="email"
                name="email"
                type="email"
                label={t('pre_registration.email')}
                rules={[...new Set(['is_email', ...entries.email])]}
              />
            </Grid>
          )}
          {entries?.birthDate && (
            <Grid item xs={12} md={6} className={styles.birthDateInput}>
              <label htmlFor="birth_date_day">
                {t('pre_registration.birthDate')}
              </label>
              <div>
                <Dropdown
                  id="birth_date_day"
                  name="birth_date_day"
                  placeholder={t('pre_registration.day')}
                  rules="required"
                >
                  {DAYS.map((day) => (
                    <Dropdown.Option
                      key={day}
                      value={day}
                    >
                      {day}
                    </Dropdown.Option>
                  ))}
                </Dropdown>
                <Dropdown
                  id="birth_date_month"
                  name="birth_date_month"
                  placeholder={t('pre_registration.month')}
                  rules="required"
                >
                  {months.map((month, i) => (
                    <Dropdown.Option
                      key={month}
                      value={`${i + 1}`}
                    >
                      {month}
                    </Dropdown.Option>
                  ))}
                </Dropdown>
                <Dropdown
                  id="birth_date_year"
                  name="birth_date_year"
                  placeholder={t('pre_registration.year')}
                  rules="required"
                >
                  {YEARS.map((year) => (
                    <Dropdown.Option
                      key={year}
                      value={year}
                    >
                      {year}
                    </Dropdown.Option>
                  ))}
                </Dropdown>
              </div>
            </Grid>
          )}

          {requiresAddressInfo && (
            <>
              <Grid item xs={12}>
                <hr />
              </Grid>

              <Grid item xs={12}>
                <h3>{t('pre_registration.address')}</h3>
              </Grid>

              {entries?.addressStreet && (
                <Grid item xs={12} md={6}>
                  <Input
                    id="address_street"
                    name="address_street"
                    label={t('pre_registration.street_name')}
                    rules={entries.addressStreet}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  {entries?.addressStreetNumber && (
                    <Grid item xs={6}>
                      <Input
                        id="address_street_number"
                        name="address_street_number"
                        type="text"
                        label={t('pre_registration.house_number')}
                        rules={entries.addressStreetNumber}
                      />
                    </Grid>
                  )}
                  {entries?.addressPostalCode && (
                    <Grid item xs={6}>
                      <Input
                        id="address_postal_code"
                        name="address_postal_code"
                        label={t('pre_registration.postal_code')}
                        rules={entries.addressPostalCode}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {entries?.addressCity && (
                <Grid item xs={12} md={6}>
                  <Input
                    id="address_city"
                    name="address_city"
                    label={t('pre_registration.city')}
                    rules={entries.addressCity}
                  />
                </Grid>
              )}
              {entries?.addressProvince && (
                <Grid item xs={12} md={6}>
                  <Dropdown
                    id="address_province"
                    name="address_province"
                    className={styles.provinceDropdown}
                    label={t('pre_registration.province')}
                    placeholder={t('pre_registration.select')}
                    rules={entries.addressProvince}
                  >
                    {provinces.map((province) => (
                      <Dropdown.Option
                        key={province}
                        value={province}
                      >
                        {province}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Panel>
      <div className={styles.footer}>
        <div className={styles.footerButtons}>
          <Button type="button" outline onClick={handleConfirmCancel}>
            {t('checkout.cancel_order')}
          </Button>
          <Button
            loading={loading}
            type="submit"
          >
            <span>{t('checkout.proceed_to_method')}</span>
            <ArrowForward />
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default PreRegistrationView;
