export default {
  or: 'of',
  send: 'Versturen',
  go_back: 'Ga terug',
  go_to: ({ panel }) => `Ga naar ${panel}`,
  cancel: 'Annuleer',
  teachers: 'Coaches',
  loading: 'Aan het laden...',

  menu: {
    account_details: 'Account details',
    subscription: 'Abonnement',
    sign_out: 'Uitloggen',
    help: 'Help center',
  },

  methods: {
    tooltip: 'Meer informatie',
  },

  panels: {
    student: 'Catalogus',
    coach: 'Coach omgeving',
    admin: 'Dashboard',
  },

  error: 'Er ging iets mis! Excuses voor het ongemak. Herlaad de pagina en probeer opnieuw.',
  errors: {
    CODE_INVALID: 'Deze code voldoet niet aan het verwachtte formaat.',
    CODE_NOT_FOUND: 'Deze code is niet gevonden binnen ons systeem.',
    CODE_ALREADY_USED: 'Deze code is reeds geactiveerd.',
    CODE_EXPIRED: 'Deze geldigheid van deze code is verlopen',
    PROCESSING_ERROR: 'Er ging iets mis! Excuses voor het ongemak. Wij gaan het probleem onderzoeken. Probeer het later opnieuw of neem contact met ons op.',
    ERROR_500: 'Er ging iets mis! Excuses voor het ongemak. Neem contact op met de helpdesk om dit probleem spoedig te verhelpen.',
  },

  forgot_password: {
    title: 'Wachtwoord vergeten?',
    description: 'Vul je email adres in en we sturen je een herstel-link.',
    email: 'E-mail adres',
    messages: {
      success: 'Succes! Je krijgt binnen enkele minuten een mail.',
      invalid: 'Dit email adres is niet bij ons onbekend.',
      not_found: 'Jouw wachtwoord is niet gevonden in het systeem.',
    },
  },

  login: {
    title: 'Log in met wachtwoord',
    subtitle: 'Hiermee kun jij onbeperkt leren.',
    email: 'E-mail adres',
    password: 'Wachtwoord',
    register: 'Registreer',
    forgot_password: 'Wachtwoord vergeten?',
    action: 'Inloggen',
    no_account: 'Nog geen account?',
    no_account_register: 'Meld je direct aan!',
    messages: {
      failed: 'Inloggen is mislukt. Probeer het opnieuw.',
      error: 'Er gaat iets mis aan onze kant. Probeer het later opnieuw',
    },
  },

  method: {
    title: 'Log in',
    subtitle: 'Kies via welke route je wilt inloggen.',
    login: 'Inloggen bij ',
    register: 'Registreren bij ',
  },

  pre_registration: {
    personal_details: 'Persoonsgegevens',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    bsn: 'BSN',
    gender: 'Geslacht',
    email: 'E-mailadres',
    birthDate: 'Geboortedatum',
    address: 'Adres',
    street_name: 'Straatnaam',
    house_number: 'Huisnummer',
    postal_code: 'Postcode',
    city: 'Woonplaats',
    province: 'Provincie',
    select: 'Kies',
    day: 'Dag',
    month: 'Maand',
    year: 'Jaar',
    man: 'Man',
    woman: 'Vrouw',
    no_data_required: 'Er is geen extra informatie nodig voor deze order.',
  },

  register: {
    title: 'Maak een account aan',
    first_name: 'Voornaam',
    last_name_insertion: 'Tussenvoegsel',
    last_name: 'Achternaam',
    email: 'E-mail adres',
    password: 'Wachtwoord',
    password_hint: 'Bedenk een veilig wachtwoord, dat minimaal 8 tekens bevat.',
    repeat_password: 'Herhaal wachtwoord',
    i_agree_with: 'Ik ga akkoord met de',
    privacy_declaration: 'Privacyverklaring',
    action: 'Registreer',
    have_an_account: 'Heb je al een account?',
    login: 'Log dan in.',
    entity: 'Organisatie',
    entity_placeholder: '-- Selecteer --',
    messages: {
      success: 'Gefeliciteerd! Je account is aangemaakt. Rond de registratie af door je account via de mail te bevestigen.',
      identical_passwords: 'Je hebt twee verschillende wachtwoorden ingevoerd. De twee wachtwoorden moeten hetzelfde zijn.',
      invalid: 'Probeer het opnieuw. Dit e-mailadres is mogelijk al in gebruik door een ander account.',
    },
  },

  reset_password: {
    title: 'Wachtwoord opnieuw instellen',
    subtitle: 'Vul een nieuw wachtwoord in.',
    email: 'E-mail adres',
    new_password: 'Nieuw wachtwoord',
    password_hint: 'Minimaal 8 tekens',
    action: 'Wachtwoord wijzigen',
    token: 'Token uit email',
    messages: {
      success: 'Het wachtwoord is aangepast - je kunt nu inloggen.',
      unauthorized: 'Met deze gegevens is het niet mogelijk om je wachtwoord te wijzigen.',
      not_found: 'Het account kon niet gevonden worden.',
    },
  },

  account: {
    email: 'E-mailadres',
    full_name: 'Naam',
    first_name: 'Voornaam*',
    last_name_insertion: 'Tussenvoegsel',
    last_name: 'Achternaam',
    phone_number: 'Telefoonnummer',
    education: 'Opleiding',
    sector: 'Sector',
    organisation: 'Organisatie',
    class: 'Klas',
    language: 'Taal',
    title: 'Mijn account',
    description: 'Verander profiel instellingen en bekijk bestelhistorie',
    personal_data: 'Persoonsgegevens',
    edit: 'Bewerken',
    save: 'Opslaan',
    cancel: 'Annuleren',
    tabs: {
      information: 'Mijn gegevens',
      coaches: 'Mijn coaches',
      primary_coach: 'Mijn hoofdcoach',
      purchases: 'Mijn aankopen',
      notifications: 'Notificaties',
    },
    primary_coach: {
      title: 'Mijn hoofdcoach',
      body: 'Voor verschillende leerprogramma\'s kun je verschillende coaches hebben. Daarnaast kun je één hoofdcoach selecteren, bij wie je altijd terecht kunt met vragen en opmerkingen. De hoofdcoach is bijvoorbeeld jouw studiebegeleider of leidinggevende. We voegen de hoofdcoach ook toe aan het hoofdmenu zodat je snel contact op kunt nemen. Selecteer hieronder jouw hoofdcoach.',
      your_primary_coach: 'Jouw hoofdcoach',
      select_your_primary_coach: '-- Selecteer jouw hoofdcoach --',
      edit: 'Wijzig coach',
      save: 'Opslaan',
      no_coaches: {
        title: 'Let op',
        message: 'Er is nog geen hoofdcoach beschikbaar voor jou.',
      },
      response: {
        success: 'Jouw hoofdcoach is succesvol gewijzigd!',
        error: 'Er ging iets mis bij het opslaan van jouw hoofdcoach. Probeer het later opnieuw.',
      },
    },
    skills_and_interests: {
      title: 'Interesses en vaardigheden',
      sub_title: 'Selecteer een aantal interesses en vaardigheden die bij jou passen. Op basis hiervan sturen we persoonlijke aanbevelingen, bijvoorbeeld voor interessante leerprogramma’s.',
      interests: 'Selecteer een aantal interesses die bij jou passen',
      skills: 'Selecteer een aantal vaardigheden die bij jou passen',
    },
    orders: {
      active: 'Actieve abonnement(en)',
      history: 'Bestelgeschiedenis',
      product: 'Product',
      purchase_date: 'Aankoopdatum',
      amount: 'Bedrag',
      deadline: 'Verloopt binnenkort',
      subscription_ending_on: 'Let op: dit abonnement eindigt ',
      extend: 'Verlengen',
      status: {
        created: 'Geannuleerd',
        payment_created: 'Geannuleerd',
        pending_payment_method: 'Geannuleerd',
        pending: 'Geannuleerd',
        fulfilled: 'Verwerkt',
        fulfilling: 'Geannuleerd',
      },
      no_subscription: {
        title: 'Geen actief abonnement',
        text: 'Je hebt momenteel nog geen actief abonnement.',
        action: 'Neem een abonnement',
      },
      no_history: {
        title: 'Nog geen bestelgeschiedenis',
        text: 'Je hebt momenteel nog geen aankopen gedaan.',
      },
    },
    feedback: {
      success: 'De wijziging is succesvol doorgevoerd!',
      error: 'Er ging iets mis. Probeer later opnieuw.',
    },
  },

  notifications: {
    title: 'Bepaal hieronder welke notificaties je wilt ontvangen.',
    platform: 'Platform',
    email: 'Email',
    category: {
      COURSE: 'Notificaties per leerprogramma',
      EXAM: 'Notificaties examen',
      GENERAL: 'Algemene notificaties',
    },
    notification_titles: {
      COURSE: {
        // Coach
        ASSIGNMENT_TURNED_IN: 'Ingeleverde opdrachten',
        ENROLLMENT_STATUS_CHANGE: 'In/uitschrijven deelnemers',
        EVENT_ENROLLMENT_STATUS_CHANGE: 'In/uitschrijven van evenementen',
        SAMPLE_SET_GENERATED: 'Toegevoegd worden als collega coach',
        ASSIGNED_AS_ASSISTANT_COACH: 'Automatische steekproef gegenereerd',
        // Student
        ASSIGNMENT_GRADED: 'Nagekeken opdrachten',
        ASSIGNMENT_FEEDBACK: 'Feedback op opdracht',
        ASSIGNMENT_AVAILABLE: 'Opdracht staat klaar',
        NEW_EVENTS_AVAILABLE: 'Nieuwe evenementen',
        SHARED_ASSIGNMENTS: 'Gedeelde opdrachten',
      },
      EXAM: {
        // Coach
        STUDENT_READY_FOR_EXAM: 'Kandidaat klaar voor examen',
        EXAM_TURNED_IN: 'Examen/herkansing ingeleverd',
        ASSIGNED_AS_SECOND_ASSESSOR: 'Toegevoegd als tweede beoordelaar',
        // Student
        EXAM_AVAILABLE: 'Examen/herkansing staat klaar',
        EXAM_GRADED: 'Examen/herkansing nagekeken',
      },
      GENERAL: {
        // Coach
        DUPLICATED_COURSE_SHARED: 'Gedupliceerde leerset/leerprogramma wordt gedeeld met jou',
        STUDENTS_TRANSFERRED: 'Overgedragen deelnemers',
        // Student & Coach
        SUBSCRIPTION_ALMOST_EXPIRES: 'Abonnement (bijna) verlopen',
        NEW_COURSE_AVAILABLE: 'Nieuwe leerprogramma’s',
        PORTFOLIO_EXPORT_READY: 'Zodra een portfolio gereed is om te downloaden',
      },
    },
  },

  checkout: {
    cancel_order: 'Annuleren',
    title: 'Je kunt bijna beginnen!',
    exVat: ({ vat }) => `Excl. ${vat ? `${vat}%` : ''} btw`,
    incVat: 'Incl. btw',
    vatFree: 'Btw vrijgesteld',

    plan: {
      access_to: 'Toegang tot:',
      not_available: {
        title: 'Je hebt reeds nog een abonnement',
        description: 'Omdat je momenteel nog een lopend abonnement hebt, kunnen we dit abonnement helaas nog niet verlengen. Zodra je abonnement is verlopen kun je wel een nieuw abonnement aanschaffen. Indien je wilt wisselen van abonnement, dan kun je contact op te nemen met de helpdesk. Excuses voor het ongemak.',
      },
    },

    choose_subscription: 'Kies een abonnement voor',
    select_payment_method: 'Selecteer een betaalmethode',
    fill_in_prepaid_code: 'Of heb je een prepaid code? Vul deze dan hieronder in:',

    cancel: {
      are_you_sure: 'Weet je het zeker dat je de bestelling wilt annuleren? Je wordt dan terug gestuurd naar de startpagina.',
      yes: 'Ja, annuleer bestelling',
      no: 'Nee',
    },

    payment_methods: {
      title: 'Kies betaalmethode',
      warnings: {
        delay: 'Het kan tot een dag duren voordat de betaling is geverifieerd met deze betaalmethode. Gedurende deze verificatie is je product nog <strong>niet</strong> actief.',
      },
      methods: {
        banktransfer: 'Bank overschrijving',
      },
      prepaid: {
        label: ({ productNames }) => `Heb je een Prepaid code voor '${productNames.join('\', \'')}'?`,
        redeem: 'Verzilveren',
        success: 'Gelukt! Je Prepaid code is verzilverd!',
        error: 'De prepaid code die je hebt ingevoerd is incorrect. Probeer het opnieuw!',
      },
    },

    prepaid_code: 'PREPAID CODE',
    redeem: 'Code verzilveren',
    usp: {
      title: 'Goed om te weten',
      first: 'Met alle abonnementen krijg je toegang tot al onze lesprogramma’s én examens.',
      second: 'Je nieuwe abonnement gaat in zodra je huidige abonnement afgelopen is',
      third: 'Abonnementen lopen tijdens de (zomer)vakanties gewoon door. Je kunt dus altijd blijven leren!',
    },

    breadcrumbs: {
      order: 'Bestelling',
      payment_methods: 'Betaalmethode',
      payment: 'Betalen',
      pre_registration: 'Persoonsgegevens',
    },

    mollie: 'De betaling wordt beveiligd en mogelijk gemaakt door Mollie Payments.',
    agree_to_terms: "Zodra je op 'Ga verder naar betalen' klikt, ga je akkoord met:",
    terms_and_conditions: 'Gebruikersvoorwaarden',
    privacy_declaration: 'Privacyverklaring',

    proceed_to_payment: 'Ga verder naar betalen',
    proceed_to_method: 'Kies betaalmethode',
    your_order: 'Jouw bestelling',
    total: 'Totaal',

    confirmation: {
      created: {
        title: 'Betaling aangemaakt.',
        text: 'De betaling is aangemaakt, maar moet nog worden voldaan en bevestigd. Dit kan tot een dag duren.',
        primary_action: 'Bekijk aankopen',
        secondary_action: 'Ga naar catalogus',
      },
      fulfilled: {
        title: 'Betaling gelukt!',
        text: 'De betaling is geverifieerd en afgerond. Je kunt nu starten met leren!',
        primary_action: 'Naar het platform!',
        secondary_action: 'Bekijk aankopen',
      },
      prepaid: {
        title: 'De prepaid code is verzilverd!',
        text: 'De prepaid code is geverifieerd en ingewisseld. Je kunt nu starten met het platform!',
        primary_action: 'Naar het platform!',
        secondary_action: 'Bekijk aankopen',
      },
      failed: {
        title: 'Betaling geannuleerd.',
        text: 'De betaling is geannuleerd. Je kunt een nieuwe bestelling plaatsen om alsnog toegang te krijgen.',
        primary_action: 'Ga naar catalogus',
        secondary_action: 'Bekijk aankopen',
      },
    },
    selected_payment_method: 'Geselecteerde betaalmethode',
    cart: {
      most_popular: 'Meest gekozen',
      pick_this_subscription: 'Kies dit abonnement',
      subscription_selected: 'Geselecteerd',
      duration: {
        '3 months': 'kwartaal / 3 maanden',
        '12 months': '1 jaar / 12 maanden',
      },
    },
    duration: {
      choose: 'Toon prijs per',
      '3 months': {
        title: 'Kwartaal',
        name: '3 maanden',
      },
      '12 months': {
        title: 'Jaar',
        name: '12 maanden',
      },
    },
  },
  validation: {
    is_required: 'Dit veld is verplicht.',
    unique: 'Email adres is al in gebruik.',
    invalid_email: 'Vul een geldig e-mailadres in, zoals naam@voorbeeld.nl.',
    invalid_phone: 'Vul een geldig telefoonnummer in, zoals 0382 022095.',
    invalid_name: 'Vul een naam in.',
  },

  months: () => [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ],
  provinces: () => [
    'Drenthe',
    'Flevoland',
    'Friesland',
    'Gelderland',
    'Groningen',
    'Limburg',
    'Noord-Brabant',
    'Noord-Holland',
    'Overijssel',
    'Utrecht',
    'Zeeland',
    'Zuid-Holland',
  ],
};
