import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useTranslator } from '@brainstud/core-learning';
import { useOrder } from '@brainstud/academy-api';
import { Container, ProgressStepper, ProgressStepperSimple } from 'Components';
import routes from 'routes';
import styles from './CheckoutLayout.module.css';

export interface CheckoutLayoutProps {
  children: React.ReactNode,
}

const CheckoutLayout = ({ children }: CheckoutLayoutProps) => {
  const orderId = window.location.hash.match(/#\/orders\/(.*)\/.*/)?.[1];
  const [{ data: order }] = useOrder({ order: orderId });
  const [t] = useTranslator();

  const hasPreRegistration = !!order?.preRegistrationRequired;
  const steps = [
    t('checkout.breadcrumbs.order'),
    ...(hasPreRegistration ? [t('checkout.breadcrumbs.pre_registration')] : []),
    t('checkout.breadcrumbs.payment_methods'),
    t('checkout.breadcrumbs.payment'),
  ];

  const routeList = [
    useRouteMatch(routes.orders.subscription.options()),
    useRouteMatch(routes.orders.payment.user_details()),
    useRouteMatch(routes.orders.payment.buy_order()),
    useRouteMatch(routes.orders.payment.confirmation()),
  ];
  const activeStep = [
    routeList[0],
    ...(hasPreRegistration ? [routeList[1]] : []),
    routeList[2],
    routeList[3],
  ].map(Boolean).indexOf(true) + 1;

  return (
    <div className={styles.checkoutLayout}>
      <Container>
        <Hidden mdUp>
          <Link
            to={routes.account.details()}
            className={styles.cancelLink}
          >
            <ChevronLeft />
            {t('checkout.cancel_order')}
          </Link>
        </Hidden>
        <ProgressStepper
          step={activeStep}
          labels={steps}
        />
        <h1>{t('checkout.title')}</h1>
        {children}
        <ProgressStepperSimple
          step={activeStep}
          labels={steps}
        />
      </Container>
    </div>
  );
};

export default CheckoutLayout;
