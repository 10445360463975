import { TValidator } from '../Hooks/useValidator';

const nameRegex = new RegExp(/[^0-9><()@#$%^]/);
const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const phoneRegex = new RegExp(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/);

function is_string(input: null | string): input is string {
  return input !== null && input.trim().length !== 0;
}

export function is_required(input: null | string): ReturnType<TValidator> {
  return !is_string(input) ? 'is_required' : null;
}

export function valid_email(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !emailRegex.test(input) ? 'invalid_email' : null;
}
export function valid_phone(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !phoneRegex.test(input) ? 'invalid_phone' : null;
}

export function valid_name(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !nameRegex.test(input) ? 'invalid_name' : null;
}
