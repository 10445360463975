import React from 'react';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import { Product } from '@brainstud/academy-api';
import { sanitizer, useTranslator } from '@brainstud/core-learning';
import { CheckCircle } from '@material-ui/icons';
import Badge from '../Badge/Badge';
import styles from './PlanCard.module.css';

const cx = classNames.bind(styles);

interface ISubscription {
  plan: Product,
  selected: boolean,
  onSelect: (a: string | null) => void,
  disabled: boolean,
}

/**
 * PlanCard.
 *
 * Clickable card to select a plan to purchase.
 */
export const PlanCard = ({
  plan, selected, onSelect, disabled,
}: ISubscription) => {
  const [t] = useTranslator();

  return (
    <Grid item xs={12} md={4}>
      <button type="button" onClick={() => onSelect(plan.id)} className={cx(styles.base, { selected, disabled })}>
        <div className={styles.title}>
          <strong>{plan.name}</strong>
          {plan.metadata?.labels?.popular && (
            <Badge className={styles.badge} scheme="blue">
              {t('checkout.cart.most_popular')}
            </Badge>
          )}
        </div>
        <div className={cx(styles.duration)}>{t(`checkout.cart.duration.${plan.productModel?.().duration}`)}</div>
        <strong className={styles.price}>
          <span>&euro;</span>
          {plan.priceAfterVat?.toFixed(2).replace(/\./, ',')}
        </strong>
        {plan.metadata?.media?.icon && (
          <div className={styles.icon}>
            <img src={plan.metadata.media.icon} alt="icon" />
          </div>
        )}
        {plan.metadata?.informationLines?.features && (
          <div className={styles.features}>
            <span>{t('checkout.plan.access_to')}</span>
            {plan.metadata.informationLines.features.map((feature) => (
              <div className={styles.feature} key={feature}>
                <CheckCircle />
                <span>{feature}</span>
              </div>
            ))}
          </div>
        )}
        {plan.metadata?.labels?.warning && (
          <span
            className={styles.warning}
            dangerouslySetInnerHTML={{ __html: sanitizer(plan.metadata.labels.warning) }}
          />
        )}
      </button>
    </Grid>
  );
};
