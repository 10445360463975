import React from 'react';
import { Redirect, Route, Switch as RouterSwitch } from 'react-router-dom';
import {
  AccountTabs, Base, CheckoutLayout, Header,
} from 'Layouts';
import routes from 'routes';
import AccountView from './AccountView/AccountView';
import { PrimaryCoachView } from './PrimaryCoachView/PrimaryCoachView';
import OrdersView from './OrdersView/OrdersView';
import SubscriptionsView from './SubscriptionsView/SubscriptionsView';
import PreRegistrationView from './PreRegistrationView/PreRegistrationView';
import PaymentMethodsView from './PaymentViews/PaymentMethodsView';
import ConfirmationView from './ConfirmationView/ConfirmationView';
import { BackButton } from '../../Components';
import NotificationPreferencesView from './NotificationPreferencesView/NotificationPreferencesView';

const Authenticated = () => (
  <>
    <BackButton />
    <Base>
      <Header />
      <RouterSwitch>
        <Route path={routes.orders.root()}>
          <CheckoutLayout>
            <RouterSwitch>
              <Route path={routes.orders.subscription.options()} exact>
                <SubscriptionsView />
              </Route>

              <Route path={routes.orders.payment.user_details()} exact>
                <PreRegistrationView />
              </Route>

              <Route path={routes.orders.payment.buy_order()} exact>
                <PaymentMethodsView />
              </Route>

              <Route
                path={[routes.orders.payment.confirmation(), routes.orders.prepaid.confirmation()]}
                exact
              >
                <ConfirmationView />
              </Route>

              <Route>
                <Redirect to={routes.account.purchases()} />
              </Route>
            </RouterSwitch>
          </CheckoutLayout>
        </Route>

        <Route path={routes.account.root()}>
          <AccountTabs>
            <RouterSwitch>
              <Route path={routes.account.details()}>
                <AccountView />
              </Route>
              <Route path={routes.account.primary_coach()}>
                <PrimaryCoachView />
              </Route>
              <Route path={routes.account.purchases()}>
                <OrdersView />
              </Route>
              <Route path={routes.account.notifications()}>
                <NotificationPreferencesView />
              </Route>
              <Route>
                <Redirect to={routes.account.details()} />
              </Route>
            </RouterSwitch>
          </AccountTabs>
        </Route>
      </RouterSwitch>
    </Base>
  </>
);

export default Authenticated;
