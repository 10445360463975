import React, {
  useCallback, useMemo,
} from 'react';
import {
  Dropdown,
} from '@brainstud/ui';
import { Grid } from '@material-ui/core';
import {
  useAccount, useAccountPreferences, QueryClient, useMe,
} from '@brainstud/academy-api';
import { useTranslator } from '@brainstud/core-learning';
import classNames from 'classnames/bind';
import { AutosaveInput, Loading } from '../../../Components';
import styles from './AccountView.module.css';
import { SkillsAndInterestsFormView } from './SkillsAndInterestsFormView';
import {
  is_required, valid_email, valid_name, valid_phone,
} from '../../../Utils/validators';

const cx = classNames.bind(styles);

const AccountView = () => {
  const [{ data: account, update }, { isLoading }] = useAccount({
    include: ['entity'],
  });
  const [me] = useMe();
  const locale = useMemo(() => me?.account?.()?.locale, [me]);
  const [t] = useTranslator();
  const entity = useMemo(() => account?.entity?.().name, [account]);

  const roles = useMemo(() => me?.account?.()?.roles || [], [me]);
  const isCoach = roles.includes('coach');

  const [{ create }] = useAccountPreferences({ property: 'locale' });
  const handleLanguageChange = useCallback(async (choice) => {
    await create.mutateAsync({ payload: choice }, {
      onSuccess: () => {
        QueryClient.invalidateQueries(['v1.me']);
      },
    });
  }, [create]);

  const handleSubmit = useCallback(async (value, name) => update.mutateAsync({
    [name]: value,
  }), [update]);

  return (
    <div className={cx(styles.account)}>
      {isLoading || !account
        ? <Loading />
        : (
          <>
            <h2>
              {t('account.personal_data')}
            </h2>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {!isCoach ? (
                  <div>
                    <strong>{t('account.full_name')}</strong>
                    <p>{account.fullName}</p>
                  </div>
                ) : (
                  <AutosaveInput
                    name="first_name"
                    label={t('account.first_name')}
                    onSave={handleSubmit}
                    defaultValue={account.firstName}
                    validator={[is_required, valid_name]}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutosaveInput
                  name="email_address"
                  label={t('account.email')}
                  type="email"
                  onSave={handleSubmit}
                  defaultValue={account.emailAddress}
                  validator={valid_email}
                />
              </Grid>
              {isCoach && (
              <Grid item xs={12} sm={6}>
                <AutosaveInput
                  name="last_name_insertion"
                  label={t('account.last_name_insertion')}
                  onSave={handleSubmit}
                  defaultValue={account.lastNameInsertion}
                />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <AutosaveInput
                  name="phone_number"
                  label={t('account.phone_number')}
                  onSave={handleSubmit}
                  defaultValue={account.phoneNumber}
                  validator={valid_phone}
                />
              </Grid>
              {isCoach && (
                <Grid item xs={12} sm={6}>
                  <AutosaveInput
                    name="last_name"
                    label={t('account.last_name')}
                    onSave={handleSubmit}
                    validator={valid_name}
                    defaultValue={account.lastName}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <div>
                  <Dropdown
                    label={t('account.language')}
                    disabled={create.isLoading}
                    className={cx(styles.dropdown)}
                    block
                    defaultValue={create?.data?.data.payload || locale}
                    onChange={handleLanguageChange}
                  >
                    <Dropdown.Option value="nl">Nederlands</Dropdown.Option>
                    <Dropdown.Option value="en">English</Dropdown.Option>
                  </Dropdown>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutosaveInput
                  name="education"
                  label={t('account.education')}
                  onSave={handleSubmit}
                  defaultValue={account.education}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutosaveInput
                  name="sector"
                  label={t('account.sector')}
                  onSave={handleSubmit}
                  defaultValue={account.sector}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <strong>{t('account.organisation')}</strong>
                  <p>{entity}</p>
                </div>
              </Grid>
              {/* FIXME classCode is deprecated, discuss with backend which to use? */}
              {!isCoach && account.classUnit && (
              <Grid item xs={12} sm={6}>
                <div>
                  <strong>{t('account.class')}</strong>
                  <p>{account.classUnit}</p>
                </div>
              </Grid>
              )}
            </Grid>
            {!isLoading && (
              <SkillsAndInterestsFormView
                account={account}
              />
            )}
          </>
        )}
    </div>
  );
};
export default AccountView;
