import React from 'react';
import { AuthenticationMethod } from '@brainstud/academy-api';
import { ContentModal } from '@brainstud/ui';
import { sanitizer } from '@brainstud/core-learning';

type Props = {
  method: AuthenticationMethod,
  closeModal: () => void
};

const MethodTooltip = ({ method, closeModal }: Props) => (
  <ContentModal onClose={closeModal} onClickOutside={closeModal} style={{ width: '45rem' }}>
    <h2>{method.label}</h2>
    <div dangerouslySetInnerHTML={{ __html: sanitizer(method.tooltip) }} />
  </ContentModal>
);

export default MethodTooltip;
