import { useOrder } from '@brainstud/academy-api';
import { useParams } from 'react-router';
import routes from '../../../routes';
import { TOrderState, TOrderStatus } from './ConfirmationView';

export default function useOrderConfirmation(): TOrderStatus | false {
  const { orderId } = useParams<{ orderId?: string }>();
  const [{ data: order }] = useOrder({ order: orderId });
  const state = ({
    loading: 'created',
    created: 'created',
    pending_payment_method: 'created',
    payment_created: 'created',
    payment_received: 'fulfilled',
    fulfilling: 'fulfilled',
    fulfilled: 'fulfilled',
    cancelled: 'failed',
    withdrawn: 'failed',
  } as { [t: string]: TOrderState })[order?.state || 'loading'];
  const courseLink = order?.links?.redirect?.href;
  const primaryAction = state !== 'fulfilled' ? { to: routes.account.purchases() } : { href: courseLink || window.location.origin };
  const secondaryAction = state !== 'fulfilled' ? { href: window.location.origin } : { to: routes.account.purchases() };

  return orderId ? {
    isLoading: !order,
    state,
    primaryAction,
    secondaryAction,
  } : false;
}
