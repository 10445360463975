import React from 'react';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import { Product } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { Badge } from 'Components';
import { useTranslator } from '@brainstud/core-learning';
import styles from './SubscriptionCard.module.css';

const cx = classNames.bind(styles);

interface ISubscription {
  subscription: Product,
  selected: boolean,
  onSelect: (a: string | null) => void,
  disabled: boolean,
}

export const SubscriptionCard = ({
  subscription, selected, onSelect, disabled,
}: ISubscription) => {
  const [t] = useTranslator();

  return (
    <Grid item xs={12} md={3} className={cx(styles.base, { selected, disabled })}>
      <div>
        <div className={styles.title}>
          <strong>{subscription.name.replace('abonnement', '')}</strong>
          {subscription.metadata?.labels?.popular && (
            <Badge className={styles.badge} scheme="blue">
              {t('checkout.cart.most_popular')}
            </Badge>
          )}
        </div>
        <strong className={styles.price}>
          <span>&euro;</span>
          {subscription.priceAfterVat?.toFixed(2).replace(/\./, ',')}
        </strong>
        {subscription.metadata?.media?.icon && (
          <div className={styles.icon}>
            <img src={subscription.metadata.media.icon} alt="icon" />
          </div>
        )}
        <div className={cx(styles['submit-button'])}>
          <Button
            disabled={disabled}
            outline={!selected}
            onClick={() => onSelect(subscription.id)}
          >
            {selected ? t('checkout.cart.subscription_selected') : t('checkout.cart.pick_this_subscription')}
          </Button>
        </div>
      </div>
    </Grid>
  );
};
