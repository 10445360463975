import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@brainstud/ui';
import { ChevronLeft } from '@material-ui/icons';
import { useTranslator } from '@brainstud/core-learning';
import { useMe } from '@brainstud/academy-api';
import styles from './BackButton.module.css';

const cx = classNames.bind(styles);

export default () => {
  const [t] = useTranslator();
  const [me] = useMe();
  const role = useMemo(() => me?.account?.()?.role || 'student', [me]);

  const panel = ({
    coach: t('panels.coach'),
    student: t('panels.student'),
    support: t('panels.admin'),
    author: t('panels.admin'),
  } as any)[role] || t('panels.student');

  return (
    <Button
      quiet
      small
      type="button"
      title={t('go_back')}
      href={window.location.origin}
      className={cx('base', 'back-button')}
    >
      <ChevronLeft style={{ fontSize: '3rem' }} />
      <span>{t('go_to', { panel })}</span>
    </Button>
  );
};
