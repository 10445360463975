import React, {
  useCallback,
  useMemo,
} from 'react';
import classNames from 'classnames/bind';
import {
  useInterests, useSkills, useAccount, Account, useMe,
} from '@brainstud/academy-api';

import { useTranslator } from '@brainstud/core-learning';
import styles from './SkillsAndInterestsFormView.module.css';
import { CheckButtonList } from '../../../Components/CheckButtonList/CheckButtonList';
import { Loading } from '../../../Components';

const classes = classNames.bind(styles);

type Props = {
  account: Account
};

/**
 * A partial view of the skills and interests view of the page
 */
export const SkillsAndInterestsFormView = ({ account }: Props) => {
  const [t] = useTranslator();
  const [{ createOrUpdate }] = useAccount();

  const [me] = useMe();

  // Skill & Interests
  const [{ data: portalSkills }, { isLoading: isLoadingSkills }] = useSkills({
    filter: {
      portal: me?.portal?.().id,
    },
  });
  const [{ data: portalInterests }, { isLoading: isLoadingInterests }] = useInterests({
    filter: {
      portal: me?.portal?.().id,
    },
  });

  const skills = useMemo(() => ((account?.skills?.() || []).map((item) => item.id)), [account]);
  const interests = useMemo(() => ((account?.interests?.() || []).map((item) => item.id)), [account]);

  const relationships = useMemo(() => ({
    skills,
    interests,
  }), [interests, skills]);

  const handleUpdateSkillsOrInterests = useCallback((name: string, value: string[]) => {
    createOrUpdate.mutateAsync({
      relationships: {
        ...relationships,
        [name]: value,
      },
    });
  }, [createOrUpdate, relationships]);

  // Interest options creation
  const interestOptions = useMemo(() => portalInterests.map((interest) => ({
    id: interest.id,
    value: interest.title,
    checked: account?.interests?.().some((item) => item.id === interest.id),
  })), [account, portalInterests]);

  // Skill options creation
  const skillsOptions = useMemo(() => portalSkills.map((skill) => ({
    id: skill.id,
    value: skill.title,
    checked: account?.skills?.().some((item) => item.id === skill.id),
  })), [account, portalSkills]);

  const isLoading = isLoadingInterests || isLoadingSkills;

  if (portalSkills.length === 0 && portalInterests.length === 0) {
    return null;
  }

  return isLoading ? <Loading /> : (
    <div className={classes(styles.base)}>
      <div className={classes(styles.heading)}>
        <h3>{t('account.skills_and_interests.title')}</h3>
        <span>{t('account.skills_and_interests.sub_title')}</span>
      </div>
      {portalInterests.length !== 0 && (
        <CheckButtonList
          label={t('account.skills_and_interests.interests')}
          name="interests"
          options={interestOptions}
          onChange={handleUpdateSkillsOrInterests}
        />
      )}
      {portalSkills.length !== 0 && (
        <CheckButtonList
          label={t('account.skills_and_interests.skills')}
          name="skills"
          options={skillsOptions}
          onChange={handleUpdateSkillsOrInterests}
        />
      )}
    </div>
  );
};
