import React, {
  useCallback, useState,
} from 'react';
import {
  Button, Callout, Dropdown,
} from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import {
  useAccount, useCoaches,
} from '@brainstud/academy-api';
import { useTranslator } from '@brainstud/core-learning';
import classNames from 'classnames/bind';
import { Edit, Save } from '@material-ui/icons';
import { Loading } from '../../../Components';
import coachDefault from '../../../Assets/images/coachDefault.svg';
import styles from './PrimaryCoachView.module.css';

const cx = classNames.bind(styles);

/**
 * A user can select a coach from all available coaches as a primary coach
 */
export const PrimaryCoachView = () => {
  const [{ data: account, update }, { isLoading }] = useAccount();
  const [t] = useTranslator();
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState<undefined | boolean>(undefined);

  const [{ data: coaches }] = useCoaches();

  const handleFormSubmit = useCallback(async (values: { primary_coach: string }) => {
    setSaved(undefined);
    return update.mutateAsync({
      _method: 'patch',
      relationships: {
        primary_coach: values.primary_coach || null,
      },
    }).then((response) => {
      setEdit(false);
      setSaved(response.statusCode === 200);
      return response;
    });
  }, [update]);

  return (
    <div className={cx(styles.account)}>
      {isLoading || !account
        ? <Loading />
        : (
          <>
            <h2>{t('account.primary_coach.title')}</h2>
            <p>{t('account.primary_coach.body')}</p>
            {coaches.length === 0 && (
              <p>
                <Callout attention>
                  <h4>{t('account.primary_coach.no_coaches.title')}</h4>
                  <p>{t('account.primary_coach.no_coaches.message')}</p>
                </Callout>
              </p>
            )}
            <Form onSubmit={handleFormSubmit} autoReset={false}>
              <div className={cx(styles['select-coach'])}>
                <img src={coachDefault} alt="coach" />
                <div>
                  <strong>{t('account.primary_coach.your_primary_coach')}</strong>
                  <div className={cx(styles['select-coach-actions'])}>
                    <Dropdown
                      searchable
                      disabled={!edit}
                      placeholder={t('account.primary_coach.select_your_primary_coach')}
                      name="primary_coach"
                      defaultValue={account?.primaryCoach?.().id}
                    >
                      {coaches.map((coach) => (
                        <Dropdown.Option key={coach.id} value={coach.id}>{coach.fullName}</Dropdown.Option>
                      ))}
                    </Dropdown>
                    {edit ? (
                      <Button quiet small type="submit" loading={update.isLoading}>
                        <Save fontSize="small" />
                        <span>{t('account.primary_coach.save')}</span>
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        quiet
                        small
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.preventDefault();
                          setEdit(!edit);
                        }}
                      >
                        <Edit fontSize="small" />
                        <span>{t('account.primary_coach.edit')}</span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {saved !== undefined && !edit && (
                <Callout success={saved} error={!saved} className={styles.banner}>
                  {t(`account.primary_coach.response.${saved ? 'success' : 'error'}`)}
                </Callout>
              )}
            </Form>
          </>
        )}
    </div>
  );
};
