import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ModalSwitch } from '@brainstud/core-learning';
import { useInitialization } from 'Hooks';
import { Loading } from 'Components';
import { AppProvider } from 'Layouts';
import { Authenticated, Unauthenticated } from 'Views';
import 'styles.css';

const App = () => {
  const [{ isAuthenticated }, { isLoading }] = useInitialization();
  const Routes = isAuthenticated ? Authenticated : Unauthenticated;

  return (
    <Router>
      <AppProvider>
        {isLoading ? (
          <Loading style={{ marginTop: '15vh' }} />
        ) : (
          <Routes />
        )}
        <ModalSwitch />
      </AppProvider>
    </Router>
  );
};

export default App;
