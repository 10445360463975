import { createContext } from 'react';

export interface AppContextInterface {
  flash: { message?: string, type?: 'success' | 'error', duration?: number },
  setFlash: (message: string, type: 'success' | 'error', redirect?: null|string, duration?: number) => void,
}

export default createContext<AppContextInterface>({
  flash: {},
  setFlash: () => {
    throw new Error('AppContext has not yet been initialized.');
  },
});
