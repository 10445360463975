type TId = string | number | null;

export default {
  account: {
    root: () => '/',
    details: () => '/details',
    primary_coach: () => '/primary-coach',
    purchases: () => '/purchases',
    notifications: () => '/notifications',
    logout: () => '/account/logout',
  },

  orders: {
    root: () => '/(orders|subscription|prepaid)',

    prepaid: {
      confirmation: () => '/prepaid/confirmation',
    },

    payment: {
      root: () => '/orders',
      user_details: (id?: TId) => `/orders/${id || ':orderId'}/user-details`,
      buy_order: (id?: TId) => `/orders/${id || ':orderId'}/methods`,
      confirmation: (id?: TId) => `/orders/${id || ':orderId'}/confirmation`,
    },
    subscription: {
      options: () => '/subscription/options',
    },
  },
};
