export default {
  or: 'or',
  send: 'Send',
  go_back: 'Go back',
  go_to: ({ panel }) => `Go to ${panel}`,
  cancel: 'Cancel',
  teachers: 'Coaches',
  loading: 'Loading...',

  error: 'Something went wrong! Apologies for the inconvenience. Try to reload the page and try again.',
  errors: {
    CODE_INVALID: 'This code does not meet the required format.',
    CODE_NOT_FOUND: 'This code was not found. Try copy/paste to avoid spelling mistakes.',
    CODE_ALREADY_USED: 'This code has already been activated.',
    CODE_EXPIRED: 'This code has expired.',
    PROCESSING_ERROR: 'Something went wrong! Apologies for the inconvenience. We will research the problem. Try again later or contact us.',
    ERROR_500: 'Something went wrong! Apologies for the inconvenience. Please contact support to fix this problem quickly.',
  },

  menu: {
    account_details: 'Account details',
    subscription: 'Subscription',
    sign_out: 'Sign out',
    help: 'Help center',
  },

  methods: {
    tooltip: 'More information',
  },

  panels: {
    student: 'Catalogus',
    coach: 'Coach omgeving',
    admin: 'Dashboard',
  },

  forgot_password: {
    title: 'Forgot password?',
    description: 'Fill in your email address and we will send you a recovery-link',
    email: 'E-mail address',
    messages: {
      success: 'Success! Within minutes, you will receive an email.',
      invalid: 'This email address is not recognized.',
      not_found: 'We could not find this email address in our database.',
    },
  },

  account: {
    save: 'Save',
    cancel: 'Cancel',
    personal_data: 'Personal details',
    edit: 'Edit',
    full_name: 'Name',
    email: 'Email address',
    first_name: 'First name*',
    last_name_insertion: 'Prefix',
    last_name: 'Last name',
    phone_number: 'Phone number',
    education: 'Education',
    sector: 'Sector',
    organisation: 'Organization',
    class: 'Class',
    title: 'My account',
    language: 'Language',
    description: 'Update your profile settings and view your order history.',
    tabs: {
      information: 'My details',
      coaches: 'My coaches',
      primary_coach: 'My primary coach',
      purchases: 'My purchases',
      notifications: 'My notifications',
    },
    primary_coach: {
      title: 'My primary coach',
      body: 'You can have different coaches for different courses. Besides that you can choose one primary coach, which you can reach out to for any questions or comments. For example, the primary coach is your supervisor or tutor. We\'re also adding the primary coach to the main menu so you can get in touch quickly. Select your primary coach below.',
      your_primary_coach: 'Your primary coach',
      select_your_primary_coach: '-- Select your primary coach --',
      edit: 'Change coach',
      save: 'Save',
      no_coaches: {
        title: 'Attention',
        message: 'There are no available coaches for you yet.',
      },
      response: {
        success: 'Your main coach has been changed successfully!',
        error: 'Something went wrong whilst saving your preferred main coach.',
      },
    },
    skills_and_interests: {
      title: 'Interests and skills',
      sub_title: 'Here you can select some interests and skills that fit you. Based on these, we can provide you with personalized recommendations, like interesting courses.',
      interests: 'Select a few interests that fit you',
      skills: 'Select a few skills that fit you',
    },
    orders: {
      active: 'Active subscription(s)',
      history: 'Order history',
      product: 'Product',
      purchase_date: 'Purchase date',
      amount: 'Amount',
      deadline: 'Ends soon',
      subscription_ending_on: 'This subscription ends ',
      extend: 'Extend',
      status: {
        created: 'Canceled',
        payment_created: 'Canceled',
        pending_payment_method: 'Canceled',
        pending: 'Canceled',
        fulfilled: 'Fulfilled',
        fulfilling: 'Canceled',
      },
      no_subscription: {
        title: 'No active subscription',
        text: 'You do not have a active subscription at this moment.',
        action: 'Buy a subscription',
      },
      no_history: {
        title: 'No past orders',
        text: 'You have not made any purchases (yet).',
      },
    },
    feedback: {
      success: 'Details updated successfully.',
      error: 'Something went wrong. Please try again.',
    },
  },

  checkout: {
    cancel_order: 'Cancel order',
    title: "You're almost ready!",
    exVat: ({ vat }) => `Ex. ${vat ? `${vat}%` : ''} VAT`,
    incVat: 'Inc. VAT',
    vatFree: 'Vat exempt',

    cancel: {
      are_you_sure: 'Are you sure you want to cancel the order? You will be send back to the start page.',
      yes: 'Yes, cancel order',
      no: 'No',
    },

    plan: {
      access_to: 'Access to:',
      not_available: {
        title: 'You already have an active subscription',
        description: 'You cannot extend or change your subscription at this point. When your subscription has expired, you can buy a new subscription. When you want to change your current subscription, please contact the helpdesk. Apologies for the inconvenience.',
      },
    },

    choose_subscription: 'Choose a subscription for',
    select_payment_method: 'Choose a payment method',
    payment_methods: {
      title: 'Select a payment method',
      warnings: {
        delay: 'It can take up to a day to verify the payment with this method. During this verification the product will <strong>not</strong> be accessible.',
      },
      methods: {
        banktransfer: 'Bank transfer',
      },
      prepaid: {
        label: ({ productNames }) => `Do you have a Prepaid code for '${productNames.join('\', \'')}'?`,
        redeem: 'Redeem',
        success: 'Success! Your Prepaid code has been redeemed!',
        error: 'The prepaid code you entered is incorrect. Please try again!',
      },
    },

    fill_in_prepaid_code: 'Got a prepaid code? Enter it below:',
    prepaid_code: 'PREPAID CODE',
    redeem: 'Redeem your prepaid code',
    usp: {
      title: 'Good to know',
      first: 'All subscriptions give you access to our entire catalogue of courses and exams.',
      second: 'If you already have a subscription, your new subscription will start once your current subscription ends.',
      third: "Subscriptions remain valid throughout (school) holidays so you'll always be able to keep learning!",
    },

    breadcrumbs: {
      order: 'Order',
      payment_methods: 'Payment method',
      payment: 'Payment',
    },

    mollie: 'This payment is secured and made possible by Mollie Payments.',
    agree_to_terms: 'By clicking on "Proceed to checkout", you agree to:',
    terms_and_conditions: 'Our Terms and conditions',
    privacy_declaration: 'The privacy declaration',

    proceed_to_payment: 'Proceed to checkout',
    your_order: 'Your order',
    edit_order: 'Edit order',
    total: 'Total',
    selected_payment_method: 'Selected payment method',

    confirmation: {
      created: {
        title: 'Payment created.',
        text: 'The payment has been created, but the payment needs to be fulfilled and validated.',
        primary_action: 'Go to purchases',
        secondary_action: 'Go to catalog',
      },
      fulfilled: {
        title: 'Payment successful!',
        text: 'The payment has been fulfilled and validated. You can now start with the platform!',
        primary_action: 'Go to the platform!',
        secondary_action: 'Go to purchases',
      },
      prepaid: {
        title: 'The prepaid code has been activated!',
        text: 'The prepaid code has been validated. You can now start with the platform!',
        primary_action: 'Go to catalog!',
        secondary_action: 'Go to purchases',
      },
      failed: {
        title: 'Payment canceled.',
        text: 'The payment has been canceled. You can place a new order to still get access.',
        primary_action: 'Go to catalog',
        secondary_action: 'Go to purchases',
      },
    },
    cart: {
      most_popular: 'Most popular',
      pick_this_subscription: 'Pick this subscription',
      subscription_selected: 'Selected',
      duration: {
        '3 months': 'quarter / 3 months',
        '12 months': '1 year / 12 months',
      },
    },
    duration: {
      choose: 'Show price per',
      '3 months': {
        title: 'Quarter',
        name: '3 months',
      },
      '12 months': {
        title: 'Year',
        name: '12 months',
      },
    },
  },

  login: {
    title: 'Log in with credentials',
    subtitle: 'Don’t have an account? Sign up first.',
    email: 'E-mail address',
    password: 'Password',
    register: 'Sign up',
    forgot_password: 'Forgot password?',
    action: 'Log in',
    no_account: 'Don’t have an account?',
    no_account_register: 'Sign up first!',
    messages: {
      failed: 'Login was unsuccessful. Try again.',
      error: 'Something went wrong. Please try again later. Sorry for the inconvenience.',
    },
  },

  method: {
    title: 'Log in',
    subtitle: 'Choose the login option you prefer.',
    login: 'Log in to ',
    register: 'Sign up to ',
  },

  pre_registration: {
    personal_details: 'Personal details',
    first_name: 'First name',
    last_name: 'Surname',
    bsn: 'SSN',
    gender: 'Gender',
    email: 'Email address',
    birthDate: 'Date of birth',
    address: 'Address',
    street_name: 'Street',
    house_number: 'House number',
    postal_code: 'Postal code',
    city: 'City',
    province: 'Province',
    select: 'Select',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    man: 'Man',
    woman: 'Woman',
    no_data_required: 'No extra information is required for this transaction.',
  },

  register: {
    title: 'Create an account',
    first_name: 'First name',
    last_name_insertion: 'Prefix surname',
    last_name: 'Surname',
    email: 'E-mail address',
    password: 'Password',
    password_hint: 'Come up with a secure password, that contains at least 8 characters.',
    repeat_password: 'Repeat password',
    i_agree_with: 'I agree with the',
    privacy_declaration: 'Privacy statement',
    action: 'Register',
    have_an_account: 'Do you already have an account?',
    login: 'Log in.',
    entity: 'Organisation',
    entity_placeholder: '-- Select --',
    messages: {
      success: 'Congratulations! Your account has been created. Confirm your email address with the email you have received.',
      identical_passwords: 'The passwords did not match. Please fill in two identical passwords.',
      invalid: 'Try again. This email address may be in use.',
    },
  },

  reset_password: {
    title: 'Reset password',
    subtitle: 'Fill in a new secure password.',
    email: 'E-mail address',
    new_password: 'New password',
    password_hint: 'At least 8 characters',
    action: 'Change password',
    token: 'Copy token from email',
    messages: {
      success: 'The password has been changed - You can log in now.',
      unauthorized: 'You do not have the appropriate authorizations to change this password.',
      not_found: 'We could not find the account. Please try again.',
    },
  },

  notifications: {
    title: 'Please indicate below which notifications you would like to receive, and in what way.',
    platform: 'Platform',
    email: 'E-mail',
    category: {
      COURSE: 'Course notifications',
      EXAM: 'Exam notifications',
      GENERAL: 'Other notifications',
    },
    notification_titles: {
      COURSE: {
        // Coach
        ASSIGNMENT_TURNED_IN: 'Newly submitted assignments',
        ENROLLMENT_STATUS_CHANGE: 'Newly enrolled/unenrolled participants',
        EVENT_ENROLLMENT_STATUS_CHANGE: 'Newly enrolled/unenrolled events',
        SAMPLE_SET_GENERATED: 'Assigned as fellow coach',
        ASSIGNED_AS_ASSISTANT_COACH: 'Automatically generated sample sets',
        // Student
        ASSIGNMENT_GRADED: 'Graded assignments',
        ASSIGNMENT_FEEDBACK: 'Assignments that received feedback',
        ASSIGNMENT_AVAILABLE: 'Assignment available',
        NEW_EVENTS_AVAILABLE: 'New events',
        SHARED_ASSIGNMENTS: 'When assignment is shared with you',
      },
      EXAM: {
        // Coach
        STUDENT_READY_FOR_EXAM: 'Participant ready for exam',
        EXAM_TURNED_IN: 'Newly submitted exams',
        ASSIGNED_AS_SECOND_ASSESSOR: 'Assigned as exam assessor',
        // Student
        EXAM_AVAILABLE: 'Exam/resit is available',
        EXAM_GRADED: 'Exam/resit is graded',
      },
      GENERAL: {
        // Coach
        DUPLICATED_COURSE_SHARED: 'Someone shared a learning set with you',
        STUDENTS_TRANSFERRED: 'Participants are transferred to you',
        // Student & Coach
        SUBSCRIPTION_ALMOST_EXPIRES: 'Subscription (almost) expired',
        NEW_COURSE_AVAILABLE: 'New courses available',
        PORTFOLIO_EXPORT_READY: 'When a portfolio is ready for download',
      },
    },
  },
  validation: {
    is_required: 'This field is required.',
    unique: 'Email address already in use.',
    invalid_email: 'Please enter a valid email address, like name@example.com.',
    invalid_phone: 'Please enter a valid phone number, like +3138 2022095.',
    invalid_name: 'Please enter a valid name.',
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Augustus',
    'September',
    'October',
    'November',
    'December',
  ],
  provinces: [
    'Drenthe',
    'Flevoland',
    'Friesland',
    'Gelderland',
    'Groningen',
    'Limburg',
    'Noord-Brabant',
    'Noord-Holland',
    'Overijssel',
    'Utrecht',
    'Zeeland',
    'Zuid-Holland',
  ],
};
