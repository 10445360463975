import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Textarea, Input, InputProps, Indicator,
} from '@brainstud/ui';
import { useTranslator } from '@brainstud/core-learning';
import classNames from 'classnames/bind';
import styles from './AutosaveInput.module.css';
import { useValidator, TValidator } from '../../Hooks/useValidator';

const cx = classNames.bind(styles);

const inputs = {
  input: Input,
  textarea: Textarea,
};

interface AutosaveInputProps extends InputProps {
  /** Value that is shown on mount */
  defaultValue?: string,
  /** The label shown above the input */
  label?: string,
  /** Requires a name for the input */
  name: string;
  /** Method executed when the user stops typing */
  onSave?: (value?: string | number, name?: string) => Promise<any>,
  /** Switch between HTMLInputElement or HTMLTextArea as input element */
  element?: 'input' | 'textarea',
  /** Type of the input, e.g. 'email', 'text' or 'number' */
  type?: string,
  /** Provide one or multiple validator functions that validate the input on change */
  validator?: TValidator | TValidator[]
}

/**
 * An Input or Textarea component that has an auto-save feature when the user stops typing
 */
export const AutosaveInput = ({
  defaultValue = '',
  label = undefined,
  name,
  onSave,
  element = 'input',
  type,
  validator,
}: AutosaveInputProps) => {
  const [value, setValue] = useState(defaultValue);
  const [savedValue, setSavedValue] = useState(defaultValue);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const dirty = useMemo(() => value !== savedValue, [value, savedValue]);
  const [t] = useTranslator();

  const errors = useValidator(validator, value);
  const hasErrors = typeof errors !== 'undefined';

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);

  const [error, setError] = useState(false);

  const handleSave = useCallback(() => {
    if (!hasErrors && dirty) {
      if (timer.current) clearTimeout(timer.current);
      if (onSave) {
        setLoading(true);
        setSuccess(false);
        onSave(value, name)
          .then(() => {
            setLoading(false);
            setSuccess(true);
          })
          .catch(() => {
            setLoading(false);
            setError(true);
          });
      }
      setSavedValue(value);
    }
  }, [hasErrors, dirty, onSave, value, name]);

  useEffect(() => {
    if (dirty) {
      timer.current = setTimeout(() => handleSave(), 1000);
      return () => {
        if (timer.current) clearTimeout(timer.current);
      };
    }
  }, [value, savedValue, handleSave, dirty]);

  const InputComponent = inputs[element];

  return (
    <div className={styles.base}>
      <InputComponent
        label={label}
        name={name}
        value={value}
        type={type}
        onBlur={handleSave}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className={styles.statusWrap}>
        <Indicator
          className={cx(styles.indicator, { hidden: !dirty })}
          loading={loading}
          success={success}
          error={error || !!errors}
        />
      </div>
      {dirty && errors && (
        <span className={cx(styles.error)}>
          {t(`validation.${errors[0]}`)}
        </span>
      )}
    </div>
  );
};
