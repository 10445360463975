import React, { useState, useCallback } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useConfig, useTranslator } from '@brainstud/core-learning';
import { Form } from '@brainstud/universal-components';
import { Button, Input, Panel } from '@brainstud/ui';
import useApp from 'Layouts/AppProvider/useApp';
import { SubHeader } from 'Layouts';
import classNames from 'classnames/bind';
import styles from './ResetPasswordView.module.css';

const cx = classNames.bind(styles);

type FormData = {
  email_address: string,
  new_password: string,
  token: string,
};

const ResetPasswordView = () => {
  const [t] = useTranslator();

  const getFeedbackMessage = (statusCode: number) => {
    if (statusCode === 200) return t('reset_password.messages.success');
    if (statusCode === 201) return t('reset_password.messages.success');
    if (statusCode === 202) return t('reset_password.messages.success');
    if (statusCode === 403) return t('reset_password.messages.unauthorized');
    if (statusCode === 404) return t('reset_password.messages.not_found');
    if (statusCode === 422) return t('reset_password.messages.unauthorized');
    return '';
  };

  const parameters = queryString.parse(window.location.search);

  const endpoint = useConfig('api.baseUrl');

  const { setFlash } = useApp();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback((values: FormData) => {
    setLoading(true);
    axios.post(`${endpoint}/v0/account_actions/change_password`, {
      email_address: values.email_address,
      password: values.new_password,
      password_confirmation: values.new_password,
      token: parameters.token || values.token,
    })
      .then((response) => {
        setLoading(false);
        setFlash(getFeedbackMessage(response.status), 'success', '/login', 5000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error); // eslint-disable-line
        setFlash(getFeedbackMessage(error?.response?.status), 'error', null, 9000);
      });
  }, [endpoint, getFeedbackMessage]);

  return (
    <Panel className={cx(styles.base)}>
      <SubHeader back>
        <h1>{t('reset_password.title')}</h1>
        <p>{t('reset_password.subtitle')}</p>
      </SubHeader>

      <Form<FormData> onSubmit={handleFormSubmit} validate>
        <Input type="email" label={t('reset_password.email')} name="email_address" defaultValue={parameters?.email?.toString()} />
        <Input type="password" label={t('reset_password.new_password')} name="new_password" rules="required|min:8" />
        {t('reset_password.password_hint')}
        {' '}
        <br />
        <Input hidden={!!parameters.token} label={t('reset_password.token')} name="token" defaultValue={parameters?.token?.toString()} />
        <div style={{ marginTop: '2rem' }}>
          <Button loading={loading}>
            {t('reset_password.action')}
            {' '}
            <ChevronRight fontSize="small" />
          </Button>
        </div>
      </Form>
    </Panel>
  );
};

export default ResetPasswordView;
