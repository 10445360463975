import React from 'react';
import { Panel } from '@brainstud/ui';
import { useTranslator } from '@brainstud/core-learning';
import { Container } from 'Components';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { useMe } from '@brainstud/academy-api';
import styles from './AccountTabs.module.css';
import routes from '../../routes';

export type AccountTabsProps = {
  children: React.ReactNode,
};

const cx = classNames.bind(styles);

const AccountTabs = ({ children }: AccountTabsProps) => {
  const [t] = useTranslator();
  const [me] = useMe();
  const isNotCoach = !(me?.account?.()?.roles.includes('coach'));

  return (
    <Container className={cx(styles.base)}>
      <h1>{t('account.title')}</h1>
      <p>{t('account.description')}</p>

      <Panel className={cx(styles.menu)}>
        <nav>
          <NavLink to={routes.account.details()}>
            <span>{t('account.tabs.information')}</span>
          </NavLink>
          {isNotCoach && (
            <NavLink to={routes.account.primary_coach()}>
              <span>{t('account.tabs.primary_coach')}</span>
            </NavLink>
          )}
          <NavLink to={routes.account.purchases()}>
            <span>{t('account.tabs.purchases')}</span>
          </NavLink>
          <NavLink to={routes.account.notifications()}>
            <span>{t('account.tabs.notifications')}</span>
          </NavLink>
        </nav>
      </Panel>

      <Panel className={styles.panel}>
        {children}
      </Panel>

    </Container>
  );
};

export default AccountTabs;
