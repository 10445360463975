import React, { useState } from 'react';
import axios from 'axios';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import { Button, Input, Panel } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { SubHeader } from 'Layouts';
import { useConfig, useTranslator } from '@brainstud/core-learning';
import useApp from 'Layouts/AppProvider/useApp';
import classNames from 'classnames/bind';
import styles from './ForgotPasswordView.module.css';

const cx = classNames.bind(styles);

const ForgotPasswordView = () => {
  const [t] = useTranslator();

  const getFeedbackMessage = (statusCode: number) => {
    if (statusCode === 202) return t('forgot_password.messages.success');
    if (statusCode === 422) return t('forgot_password.messages.invalid');
    if (statusCode === 404) return t('forgot_password.messages.not_found');
    return '';
  };

  const { setFlash } = useApp();
  const endpoint = useConfig('api.baseUrl');
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (values: { email_address: string }) => {
    setLoading(true);
    axios.post(`${endpoint}/v0/account_actions/forgot_password`, {
      email_address: values.email_address,
    })
      .then(({ status }) => {
        setLoading(false);
        setFlash(getFeedbackMessage(status), 'success', '/', 5000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error); // eslint-disable-line
        setFlash(getFeedbackMessage(error.response.status), 'error', null, 9000);
      });
  };

  return (
    <Panel className={styles.base}>
      <SubHeader back>
        <h1>{t('forgot_password.title')}</h1>
        <p>{t('forgot_password.description')}</p>
      </SubHeader>

      <Form onSubmit={handleFormSubmit}>
        <Input type="email" label={`${t('forgot_password.email')}:`} name="email_address" rules={['required', 'is_email']} />
        <div className={cx(styles['reset-button'])}>
          <Button loading={loading}>
            <span>{t('send')}</span>
            <ArrowRightAlt />
          </Button>
        </div>
      </Form>
    </Panel>
  );
};

export default ForgotPasswordView;
