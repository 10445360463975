import { useEffect, useMemo } from 'react';
import { useApi, useMe } from '@brainstud/academy-api';
import { useTheme, useTranslator } from '@brainstud/core-learning';

const useInitialization = () => {
  const { setRouteParameters } = useApi();
  const [me, { isLoading, error }] = useMe();
  const account = useMemo(() => me?.account?.(), [me]);
  const portal = useMemo(() => me?.portal?.(), [me]);

  const [,{ setLanguage }] = useTranslator();
  const [,{ setTheme }] = useTheme();
  useEffect(() => {
    if (me && account && portal) {
      setRouteParameters({
        account: account.id,
        portal: portal.id,
      });

      if (account.locale) {
        setLanguage('education', account.locale);
      }
    } else if (portal) {
      setRouteParameters({
        portal: portal.id,
      });
      const defaultLanguage = portal.parameters.find((param) => param.type === 'default_language');
      if (defaultLanguage) {
        setLanguage('education', defaultLanguage.data);
      }
    }
    if (me?.theme) setTheme(me?.theme);
    // FIXME: Enable ESLint when setLanguage is a memoized value
    // eslint-disable-next-line
  }, [me, account, portal, setRouteParameters, setTheme]);

  const isAuthenticated = !!account?.id;

  if (error) {
    throw Error(error.statusText);
  }

  return [
    { me, isAuthenticated },
    {
      isLoading,
    },
  ];
};

export default useInitialization;
