import React from 'react';
import { Redirect, Route, Switch as RouterSwitch } from 'react-router-dom';
import { Base } from 'Layouts';
import LoginView from './LoginView/LoginView';
import ForgotPasswordView from './ForgotPasswordView/ForgotPasswordView';
import ResetPasswordView from './ResetPasswordView/ResetPasswordView';
import RegisterView from './RegisterView/RegisterView';
import MethodView from './MethodView/MethodView';

const Unauthenticated = () => (
  <Base small>
    <RouterSwitch>
      <Route path="/login">
        <LoginView />
      </Route>

      <Route path="/forgot-password">
        <ForgotPasswordView />
      </Route>

      <Route path="/reset-password">
        <ResetPasswordView />
      </Route>

      <Route path="/register">
        <RegisterView />
      </Route>

      <Route path="/" exact>
        <MethodView />
      </Route>

      <Route>
        <Redirect to="/" />
      </Route>
    </RouterSwitch>
  </Base>
);

export default Unauthenticated;
