import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  usePortalEntitiesIndex, useAccounts, useMe,
} from '@brainstud/academy-api';
import {
  Button, Dropdown, Input, Panel,
} from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';

import { useTranslator } from '@brainstud/core-learning';
import { SubHeader } from 'Layouts';
import useApp from 'Layouts/AppProvider/useApp';
import styles from './RegisterView.module.css';

const cx = classNames.bind(styles);

type FormData = {
  first_name: string,
  last_name: string,
  last_name_insertion: string,
  email_address: string,
  password: string,
  password_confirm: string,
  entity: string;
};

const RegisterView = () => {
  const [t] = useTranslator();
  const [me] = useMe();
  const { setFlash } = useApp();
  const [loading, setLoading] = useState(false);
  const [{ create: createAccount }] = useAccounts(undefined, { enabled: false });
  const portal = me?.portal?.();
  const privacyStatement = portal?.information?.privacyDocumentUrl;

  const [entities] = usePortalEntitiesIndex();

  const getFeedbackMessage = useCallback((statusCode: number) => {
    if (statusCode === 201) return t('register.messages.success');
    if (statusCode === 420) return t('register.messages.identical_passwords');
    if (statusCode === 422) return t('register.messages.invalid');
    return t('register.messages.error');
  }, [t]);

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = useCallback((values) => {
    if (values.password === values.password_confirm) {
      setLoading(true);
      return createAccount.mutateAsync({
        first_name: values.first_name,
        last_name_insertion: values.last_name_insertion,
        last_name: values.last_name,
        email_address: values.email_address,
        password: values.password,
        entity: entities.length === 1
          ? entities[0].id
          : values.entity,
      }, {
        onSuccess: (document) => {
          setFlash(getFeedbackMessage(document.statusCode), 'success', '/login', 5000);
        },
        onError: (errorDocument) => {
          setLoading(false);
          if (Array.isArray(errorDocument.errors) && errorDocument.statusCode !== 422) {
            setFlash(errorDocument.errors.map((error) => error.detail)
              .join('<br />'), 'error', null, 9000);
          } else if (!Array.isArray(errorDocument.errors)) {
            setFlash(Object.values(errorDocument.errors).map((error) => error).join('<br />'), 'error', null, 5000);
          }
        },
      });
    }
    setFlash(getFeedbackMessage(420), 'error', null, 9000);
  }, [setFlash, getFeedbackMessage, createAccount, entities]);

  return (
    <div className={cx(styles.base)}>
      <Panel className={cx(styles.panel)}>
        <SubHeader back>
          <h1>{t('register.title')}</h1>
        </SubHeader>

        <Form<FormData> language="nl" onSubmit={(values) => handleFormSubmit(values)}>
          <Input
            type="text"
            label={t('register.first_name')}
            name="first_name"
            rules="required|min:1|max:220"
          />
          <Input
            type="text"
            label={t('register.last_name_insertion')}
            name="last_name_insertion"
          />
          <Input
            type="text"
            label={t('register.last_name')}
            name="last_name"
            rules="required|min:1|max:220"
          />
          <Input type="email" label={t('register.email')} name="email_address" rules={['required', 'is_email']} />
          <Input type="password" label={t('register.password')} name="password" rules="required|min:8" />
          <div className={cx('hint')}>
            {t('register.password_hint')}
          </div>

          <Input
            type="password"
            label={t('register.repeat_password')}
            name="password_confirm"
            rules="required|min:8"
          />

          {entities.length > 1 && (
            <Dropdown
              id="entity"
              name="entity"
              block
              searchable={entities.length > 5}
              label={t('register.entity')}
              placeholder={t('register.entity_placeholder')}
              className={styles.entityDropdown}
            >
              {entities.map((e) => (
                <Dropdown.Option key={e.id} value={e.id}>
                  {e.name}
                </Dropdown.Option>
              ))}
            </Dropdown>
          )}

          {!privacyStatement && (
            <div className={cx('terms-checkbox')}>
              <input type="checkbox" id="terms-of-service" name="terms-of-service" required />
              <label htmlFor="terms-of-service">
                {`${t('register.i_agree_with')} `}
                <a href={privacyStatement} rel="noopener noreferrer" target="_blank">
                  {t('register.privacy_declaration')}
                </a>
                .
              </label>
            </div>
          )}

          <div style={{ marginTop: '2rem' }}>
            <Button loading={loading} style={{ marginRight: '1rem' }}>
              <span>{t('register.action')}</span>
              <ChevronRight fontSize="small" />
            </Button>
            <Button type="reset" outline to="/login">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </Panel>

      <Panel className={cx(styles.panel, 'small', 'bottom-panel')}>
        <p>
          {`${t('register.have_an_account')} `}
          <a href="#/login">{t('register.login')}</a>
        </p>
      </Panel>
    </div>
  );
};

export default RegisterView;
