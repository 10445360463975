import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import {
  useNotificationTypes,
  NotificationType,
  useNotificationPreference,
} from '@brainstud/academy-api';
import { useTranslator } from '@brainstud/core-learning';
import { Loading } from 'Components';
import styles from './NotificationPreferencesView.module.css';
import { NotificationItem } from './NotificationItem';

const cx = classNames.bind(styles);

type TGroupedByNotificationType = {
  [key: string]: NotificationType[],
};

const NotificationPreferencesView = () => {
  const [t] = useTranslator();
  const [notificationTypes, { isLoading }] = useNotificationTypes();
  const [notificationPreference] = useNotificationPreference();

  const notificationTypesByCategory = useMemo(() => (
    notificationTypes.reduce<TGroupedByNotificationType>((groupedTypes, notificationType) => {
      // eslint-disable-next-line no-param-reassign
      groupedTypes[notificationType.category] = [
        ...(groupedTypes[notificationType.category] || []),
        notificationType,
      ];
      return groupedTypes;
    }, {})
  ), [notificationTypes, notificationPreference]);

  return (isLoading) ? <Loading /> : (
    <div className={cx(styles.base)}>
      <div>
        <span>{t('notifications.title')}</span>
      </div>
      {Object.keys(notificationTypesByCategory)?.map((categoryName) => {
        const itemsOfCategory = notificationTypesByCategory[categoryName];
        return (
          <React.Fragment key={categoryName}>
            <div className={cx(styles['category-title'], styles.flex)}>
              <h4>{t(`notifications.category.${categoryName}`)}</h4>
              <div>
                <h4 className={cx(styles['settings-header'])}>{t('notifications.platform')}</h4>
                <h4 className={cx(styles['settings-header'])}>{t('notifications.email')}</h4>
              </div>
            </div>
            {
              itemsOfCategory.map((notificationItem: NotificationType) => (
                <div className={cx(styles.type, styles.flex)} key={notificationItem.key}>
                  <NotificationItem resource={notificationItem} />
                </div>
              ))
            }
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default NotificationPreferencesView;
