import React from 'react';
import classNames from 'classnames/bind';
import styles from './Container.module.css';

export interface ContainerProps {
  children: React.ReactNode,
  className?: string,
}

const cx = classNames.bind(styles);

const Container = ({ children, className }: ContainerProps) => (
  <div className={cx(styles.container, className)}>
    {children}
  </div>
);

export default Container;
