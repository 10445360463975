import React from 'react';
import className from 'classnames/bind';
import styles from './ProgressStepperSimple.module.css';

const cx = className.bind(styles);

interface IProgressStepperSimple {
  labels: string[],
  step: number,
}

const ProgressStepperSimple = ({ labels, step }: IProgressStepperSimple) => (
  <div className={cx('base')}>
    {labels.map((label, i) => (
      <React.Fragment key={label}>
        <span className={cx({ active: i + 1 === step })}>
          {label}
        </span>
        {i !== labels.length - 1 && '|'}
      </React.Fragment>
    ))}
  </div>
);

export default ProgressStepperSimple;
