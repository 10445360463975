export default {
  api: {
    online: true,
    baseUrl: process.env.REACT_APP_API_ROOT || `${window.location.origin}/api`,
    headers: {
      Authorization: (process.env.REACT_APP_BEARER) ? `Bearer ${(process.env.REACT_APP_BEARER || '').trim()}` : null,
      // Authorization: null,
    },
    includes: {
      'v1.notification_types': ['preference'],
      'v1.accounts': ['skills', 'interests', 'primary_coach'],
    },
  },

  environment: process.env.REACT_APP_ENVIRONMENT,

  storage: {
    id: 'auth',
  },

  translator: {
    type: 'education',
    language: 'nl',
  },

  theme: {
    brandColor: '100, 0, 205',
  },

  errors: {
    sentry: {
      dsn: 'https://0733b4a6516b40a3a0292c99d9b484cf@o261379.ingest.sentry.io/5538970',
      track: ['staging', 'production'],
      release: process.env.REACT_APP_RELEASE,
    },
  },

};
